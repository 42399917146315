import { useEffect, useState } from "react";
import { ISeedProps } from "react-brackets";

import { ICupBracket } from "../../../../interfaces/cupBracket";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { getHomeCupBracketListAction } from "../../../../redux/slices/cupSlice";
import { generateCupBracketRounds } from "../helper/bracket";

import TheCupBracketInfoPopup from "./TheCupBracketInfoPopup";
import TheCupBrackets from "./TheCupBrackets";

interface TheCupBracketsCardProps {
    division: string;
    card_name: string;
}

const TheCupBracketsCard = ({
    division = "",
    card_name = ""
}: TheCupBracketsCardProps) => {
    const dispatch = useAppDispatch();

    const [showBracketDetail, setShowBracketDetail] = useState(false);
    const [bracketDetail, setBracketDetail] = useState<ICupBracket>();
    const [seedDetail, setSeedDetail] = useState<ISeedProps>();


    const { homeCards, isLoadingBracketList } = useAppSelector(
        (state) => state.cup
      );
    const firstRound = (homeCards[card_name] ? generateCupBracketRounds(homeCards[card_name]) : []);
    
    useEffect(() => {
        dispatch(getHomeCupBracketListAction({
            card_name: card_name,
            division
        }));
    }, [card_name, division, dispatch]);

    return <>
      
      {isLoadingBracketList || firstRound.length === 0 ? (
        <div className="m-auto flex items-center justify-between mt-10">
          <div className="w-8 h-8 border-2 border-t-thecup-primary-main border-gray-300 rounded-full animate-spin"></div>
        </div>
      ) : (
        <div className="flex flex-col w-full">
          <TheCupBrackets
            isHome={true}
            rounds={firstRound[0]}
            brackets={homeCards[card_name]}
            setBracketDetail={(bracket: ICupBracket) => {
              setShowBracketDetail(true);
              setBracketDetail(bracket);
            }}
            setSeedDetail={(seed: ISeedProps) => {
              setSeedDetail(seed);
            }}
          />

          <TheCupBrackets
            isHome={true}
            rounds={firstRound[1]}
            brackets={homeCards[card_name]}
            setBracketDetail={(bracket: ICupBracket) => {
              setShowBracketDetail(true);
              setBracketDetail(bracket);
            }}
            setSeedDetail={(seed: ISeedProps) => {
              setSeedDetail(seed);
            }}
          />
        </div>
      )}
      <TheCupBracketInfoPopup
        bracketDetail={bracketDetail}
        seedDetail={seedDetail}
        isPopupOpen={showBracketDetail}
        closePopup={() => setShowBracketDetail(false)}
        showAccountInfo={(officeName: string) => {}}
        showRepInfo={(officeName: string) => {}}
      />
    </>
}

export default TheCupBracketsCard;
