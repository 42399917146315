const ArrowRightButtonIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      className={className}
    >
      <path
        d="M7.60123 9.71271C7.6026 9.71137 7.60394 9.71 7.6053 9.70864L11.806 5.51342C11.8392 5.48009 11.8686 5.44325 11.8939 5.40359L11.9598 5.28279L11.9872 5.20042V5.14001C12.0043 5.04745 12.0043 4.95255 11.9872 4.85996V4.80506V4.73917L11.9378 4.64581C11.9096 4.59379 11.8745 4.54576 11.8335 4.50303L7.6053 0.291336C7.32551 0.00930509 6.87005 0.00747763 6.58802 0.287269C6.58665 0.288607 6.58531 0.289971 6.58395 0.291336C6.3127 0.575631 6.3127 1.02289 6.58395 1.30721L9.08791 3.81665C9.19406 3.92492 9.19236 4.09876 9.08408 4.20491C9.0337 4.25431 8.96627 4.28241 8.89571 4.28339H0.719399C0.322106 4.28337 2.57492e-05 4.6054 0 5.00269C-2.57492e-05 5.39998 0.322003 5.72204 0.719296 5.72209H0.719399H8.89571C9.04732 5.72423 9.16853 5.84886 9.16639 6.00047C9.16539 6.07099 9.13731 6.13846 9.08791 6.18883L6.58395 8.68731C6.3092 8.97233 6.3092 9.42365 6.58395 9.70867C6.86374 9.9907 7.3192 9.99252 7.60123 9.71271Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowRightButtonIcon;
