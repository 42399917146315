import { getFontEmbedCSS, toPng } from "html-to-image";
import { createRef, useRef, useState } from "react";
import toast from "react-hot-toast";

import html2canvas from "html2canvas";
import isMobile from "is-mobile";
import { DownloadOption } from "../const/enums/DownloadOption";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  setFullScreen,
  setShowLoadingOverlay,
} from "../redux/slices/globalSlice";
import { downloadReportAsFile } from "../utils/download";
import { dataURLtoFile, shareOrCopyFile } from "../utils/share";
import { FitOption } from "../const/enums/FitOptions";
import { ViewType } from "../const/enums/ViewType";

// Custom hook for full screen and fit screen actions
export const useScreenActions = () => {
  const dispatch = useAppDispatch();
  const { reportData, reportDataInfo, currentReport } = useAppSelector(
    (state) => state.report
  );

  const numReports = 5; // Number of reports
  // Initialize states for fullscreen and fit screen
  const [isFullScreen, setIsFullScreen] = useState<boolean[]>(
    Array(numReports).fill(false)
  );
  const [fitOption, setFitOption] = useState<FitOption[]>(
    Array(numReports).fill(FitOption.SCROLL)
  );
  const [viewType, setViewType] = useState<ViewType[]>(
    Array(numReports).fill(ViewType.CHART)
  );
  const [scale, setScale] = useState<number[]>(
    Array(numReports).fill(0)
  );
  const reportRefs = useRef<Array<React.RefObject<HTMLDivElement>>>(
    Array.from({ length: numReports }, () => createRef<HTMLDivElement>())
  );

  // Fit option change logic
  const handleFitOption = (index: number, newOption: FitOption) => {
    setFitOption((prev) => {
      const newFitOption = [...prev]; // Create a copy of the previous state
      newFitOption[index] = newOption; // Toggle the specific index
      return newFitOption; // Return the updated array
    });
  };

  const toggleViewType = (index: number) => {
    setViewType((prev) => {
      const newViewType = [...prev];
      newViewType[index] = newViewType[index] === ViewType.CHART ? ViewType.TABLE : ViewType.CHART;
      return newViewType;
    })
  };

  const increaseScale = (index: number) => {
    setScale((prev) => {
      const newScale = [...prev];
      newScale[index] ++;
      return newScale;
    });
  };

  const decreaseScale = (index: number) => {
    if (scale[index] < -15) {
      return;
    }

    setScale((prev) => {
      const newScale = [...prev];
      newScale[index] --;
      return newScale;
    });
  };

  // Full screen toggle logic
  const toggleFullScreen = (index: number) => {
    const element = reportRefs.current[index].current; // Use reportRefs.current[index]

    if (element) {
      if (!isFullScreen[index]) {
        element.style.position = "fixed";
        element.style.width = "100%";
        element.style.height = "100%";
        element.style.zIndex = "30";
        element.style.left = "0";
        element.style.top = "0";
        element.style.borderRadius = "0";

        dispatch(setFullScreen(true));
      } else {
        element.style.position = "";
        element.style.width = "";
        element.style.height = "";
        element.style.zIndex = "";
        element.style.left = "";
        element.style.top = "";
        element.style.borderRadius = "";
        dispatch(setFullScreen(false));
      }
    }

    setIsFullScreen((prev) => {
      const newFullScreen = [...prev]; // Create a copy of the previous state
      newFullScreen[index] = !newFullScreen[index]; // Toggle the specific index
      return newFullScreen; // Return the updated array
    });
  };

  // Share screenshot logic
  const handleShareSC = async (
    tableRef: React.RefObject<HTMLDivElement>,
    reportName: string,
    fitOption: FitOption
  ) => {
    const tableElement = tableRef.current;

    if (tableElement) {
      dispatch(setShowLoadingOverlay(true));
      if (fitOption === FitOption.ALL) {
        const fontEmbedCss = await getFontEmbedCSS(tableElement);

        toPng(tableElement, { fontEmbedCSS: fontEmbedCss }).then((url) => {
          const file = dataURLtoFile(url, `${reportName}.jpg`);
          shareOrCopyFile(file, "Vivint", "https://www.vspn.app");
          dispatch(setShowLoadingOverlay(false));
        });
      } else {
        html2canvas(tableElement, { allowTaint: true, useCORS: true }).then(
          (canvas) => {
            const imgData = canvas.toDataURL(
              isMobile() ? "image/jpeg" : "image/png"
            );
            const file = dataURLtoFile(imgData, `${reportName}.jpg`);
            shareOrCopyFile(file, "Vivint", "https://www.vspn.app");
            dispatch(setShowLoadingOverlay(false));
          }
        );
      }
    }
  };

  const handleDownload = (selectedOption: string) => {
    if (
      selectedOption === DownloadOption.CSV ||
      selectedOption === DownloadOption.EXCEL
    ) {
      downloadReportAsFile(
        reportData.data.length > 0 ? reportData.data[0] : [],
        reportDataInfo,
        selectedOption,
        currentReport?.reportName || reportDataInfo.name
      );

      toast.success("Successfully downloaded!");
    }
  };

  return {
    isFullScreen,
    fitOption,
    viewType,
    scale,
    increaseScale,
    decreaseScale,
    toggleFullScreen,
    toggleViewType,
    handleFitOption,
    handleShareSC,
    handleDownload,
    reportRefs,
  };
};
