export const TheCupDivisionRegularList = [
  "RUBY",
  "DIAMOND",
  "JADE",
  "EMERALD",
  "ONYX",
  // "NO DIVISION",
];

export const TheCupDivisionRegularListColor = [
  "bg-thecup-ruby",
  "bg-thecup-diamond",
  "bg-thecup-jade",
  "bg-thecup-no-division",
  "bg-thecup-emerald",
  "bg-thecup-onyx",
];

export const TheCupDivisionPumaList = ["PUMA SCOUT", "PUMA"];

export const TheCupDivisionCategory = ["regular", "puma"];
