import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchHomeSheetsDataAction,
  fetchHomeSheetsInfoAction,
  fetchTrendingInfoAction,
} from "../../redux/slices/reportSlice";
import CompetitionSection from "./CompetitionSection";
import EditFavoritePopup from "./EditFavoritePopup";
import FavoriteSection from "./FavoriteSection";
import TrendingSection from "./TrendingSection";
import EditHomeCardsPopup from "./EditHomeCardsPopup";
import { SheetCategory } from "../../const/enums/HomeCompetitionCardType";
import HomeCardsSection from "./HomeCardsSection";

const Home = () => {
  const dispatch = useAppDispatch();
  const {
    featuredCompetitions,
    trendingInfos,
    homeCards,
    homeSheetsData,
    homeSheetsDataInfo,
    photos,
    isLoadingHomeSheetsData,
    isLoadingHomeSheetsInfo,
  } = useAppSelector((state) => state.report);
  const [isEditFavoriteOpen, setIsEditFavoriteOpen] = useState(false);
  const [isEditHomeCardsOpen, setIsHomeCardsOpen] = useState(false);

  const openFavoriteEdit = () => {
    setIsEditFavoriteOpen(true);
  };

  const closeFavoriteEdit = () => {
    setIsEditFavoriteOpen(false);
  };

  const openHomeCardsEdit = () => {
    setIsHomeCardsOpen(true);
  }

  const closeHomeCardsEdit = () => {
    setIsHomeCardsOpen(false);
  };

  useEffect(() => {
    dispatch(fetchTrendingInfoAction());
  }, [dispatch]);

  useEffect(() => {
    const homeSheets = homeCards.filter(card => card.sheetCategory === SheetCategory.Report)
    if (homeSheets.length === 0) return;

    dispatch(fetchHomeSheetsDataAction(homeSheets));
    dispatch(fetchHomeSheetsInfoAction(homeSheets));
  }, [dispatch, homeCards]);

  return (
    <>
      <FavoriteSection openPopup={openFavoriteEdit} />
      <CompetitionSection
        competitionName="Competitions"
        competitions={featuredCompetitions}
        showViewAll
      />
      <TrendingSection trendingInfos={trendingInfos} />

      <HomeCardsSection
        cards={homeCards}
        sheetsData={homeSheetsData}
        sheetsInfo={homeSheetsDataInfo}
        photos={photos}
        isLoading={isLoadingHomeSheetsData || isLoadingHomeSheetsInfo}
        openPopup={openHomeCardsEdit}
       />

      <EditFavoritePopup
        isPopupOpen={isEditFavoriteOpen}
        closePopup={closeFavoriteEdit}
      />

      <EditHomeCardsPopup
        isPopupOpen={isEditHomeCardsOpen}
        closePopup={closeHomeCardsEdit}
      />
    </>
  );
};

export default Home;
