import { getChartPhotoLink } from ".";
import { ReportSheetType } from "../../../const/enums/ReportSheetType";
import { TimeUnit } from "../../../const/enums/TimeUnit";
import { GroupedComparisonData } from "../../../interfaces/groupedComparisonData";
import { IReportViewSheet } from "../../../interfaces/reportView";
import { getMonthAbbreviations, getWeekNumber } from "../../../utils/date";

function groupComparisonData(data: any[], unit: string, field?: string): any[] {
  const grouped: { [key: string]: GroupedComparisonData } = {};
  let years: number[] = [];

  data.forEach((row) => {
    if (!row.group_field) return;
    const date = new Date(row.group_field);
    const year = date.getFullYear();
    years.push(year);
  });

  data.forEach((row) => {
    if (!row.group_field) return;

    const date = new Date(row.group_field);
    const year = date.getFullYear();
    const isCurrentYear = year === Math.max(...years);
    const isPreviousYear = year === Math.max(...years) - 1;

    let key: string;

    if (unit === TimeUnit.Days) {
      key = `${getMonthAbbreviations(row.group_field)}-${String(
        date.getDate()
      ).padStart(2, "0")}`; // MM-DD format
    } else if (unit === TimeUnit.Weeks) {
      key = `Week ${getWeekNumber(date)}`; // Year-WeekNumber format
    } else {
      // unit === 'months'
      key = `${getMonthAbbreviations(row.group_field)}`; // YYYY-MM format
    }

    if (!grouped[key]) {
      grouped[key] = {
        period: key,
        current: 0,
        previous: 0,
      };
    }

    if (isCurrentYear) {
      grouped[key].current = row[field || 0];
    } else if (isPreviousYear) {
      grouped[key].previous = row[field || 0];
    }
  });

  return Object.values(grouped);
}

export const processChartContent = (
  data: any[],
  photos: any[],
  groupBy: string | string[],
  groupByDate: string,
  reportSheet: IReportViewSheet
) => {
  let result = [...data];
  result.pop();

  let chartContent = result.map((row) => ({
    groupBy: row["group_field"],
    data: row[reportSheet.sheetField || 0],
    photo: getChartPhotoLink(
      row,
      photos,
      Array.isArray(groupBy) ? groupBy[0] : groupBy
    ),
  }));

  if (reportSheet.sheetType === ReportSheetType.ChartByDate) {
    return {
      tableHeader: ["Date", (reportSheet.sheetField ?? "").indexOf("sales") > -1 ? "Sales" : "Installs"],
      tableSubHeader: ["Total", chartContent.reduce((sum, data) => sum + data.data, 0).toString()],
      tableContent: chartContent.map(data => ({ 0: data.groupBy, 1: data.data})),
      chartContent
    };
  } else if (reportSheet.sheetType === ReportSheetType.Chart) {
    return {
      tableHeader: ["Region", (reportSheet.sheetField ?? "").indexOf("sales") > -1 ? "Sales" : "Installs"],
      tableSubHeader: ["Total", chartContent.reduce((sum, data) => sum + data.data, 0).toString()],
      tableContent: chartContent.sort((row1, row2) => row2.data - row1.data).map(data => ({ 0: data.groupBy, 1: data.data})),
      chartContent: chartContent.sort((row1, row2) => row2.data - row1.data)
    };
  } else if (reportSheet.sheetType === ReportSheetType.ComparisonChart) {
    const chartData = groupComparisonData(result, groupByDate, reportSheet.sheetField);
    let years: number[] = [];

    result.forEach((row) => {
      if (!row.group_field) return;
      const date = new Date(row.group_field);
      const year = date.getFullYear();
      years.push(year);
    });
    
    const currentYear = Math.max(...years);
    const previousYear = Math.max(...years) - 1;

    return {
      tableHeader: ["Date", previousYear.toString(), currentYear.toString()],
      tableSubHeader: ["Total", chartData.reduce((sum, data) => sum + data.previous, 0).toString(), chartData.reduce((sum, data) => sum + data.current, 0).toString()],
      tableContent: chartData.map(data => ({ 0: data.period, 1: data.previous, 2: data.current})),
      chartContent: chartData
    };
  }
  return { chartContent };
};
