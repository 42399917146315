const prizes = [
  {
    name: "Pro Carry-On Luggage 1",
    image:
      "https://www.figma.com/file/F4F5mL56HaVUkeNSXpVDIi/image/81c9ba65a9868f038e468f6b2a3262c1eafd1aba?fuid=1215720084589520081",
  },

  {
    name: "Pro Carry-On Luggage 2",
    image:
      "https://www.figma.com/file/F4F5mL56HaVUkeNSXpVDIi/image/81c9ba65a9868f038e468f6b2a3262c1eafd1aba?fuid=1215720084589520081",
  },
];

const TheCupPrizes = () => {
  return (
    <div className="flex flex-col gap-4 px-5 mt-[30px]">
      <div className="flex flex-col gap-3">
        {prizes.map((prize) => (
          <div
            key={prize.name}
            className="flex items-center gap-2 bg-thecup-darkGrayishBlue rounded-[15px] px-[16px] py-[15px]"
          >
            <img
              src={prize.image}
              alt={prize.name}
              className="w-[50px] h-[50px] rounded-[50%]"
            />
            <div className="font-plus text-white text-base not-italic font-bold leading-[150%]">
              {prize.name}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TheCupPrizes;
