import IconButton from "../../../Common/IconButton";
import TheCupBackIcon from "../../../icons/TheCupBackIcon";

interface TheCupHeaderProps {
  title?: string;
  onBack?: () => void;
}

const TheCupHeader = ({
  title = "The Cup XVII",
  onBack,
}: TheCupHeaderProps) => {
  return (
    <div className="w-full h-full relative pt-4">
      <div className="flex gap-2 items-center py-[2px] px-[20px]">
        <IconButton
          onClick={() => {
            onBack && onBack();
          }}
        >
          <TheCupBackIcon className="w-[24px] h-[24px] text-white" />
        </IconButton>

        <div className="text-white font-plus text-xl not-italic font-bold leading-[150%]">
          {title}
        </div>
      </div>
    </div>
  );
};

export default TheCupHeader;
