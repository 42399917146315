import { ISeedProps } from "react-brackets";
import { ICupBracket } from "../../../../interfaces/cupBracket";
import { formatDateString } from "../../../../utils/date";

import IconButton from "../../../Common/IconButton";
import MobilePopup, { MobilePopupPosition } from "../../../Common/MobilePopup";

import ArrowLeftBack from "../../../icons/ArrowBack";
import ArrowRightButtonIcon from "../../../icons/ArrowRightButtonIcon";
import TheCupWinnerBadgeIcon from "../../../icons/TheCupWinnerBadgeIcon";

const prizes = [
  {
    name: "Pro Carry-On Luggage 1",
    image:
      "https://www.figma.com/file/F4F5mL56HaVUkeNSXpVDIi/image/81c9ba65a9868f038e468f6b2a3262c1eafd1aba?fuid=1215720084589520081",
  },
];

interface TheCupBracketInfoPopupProps {
  bracketDetail?: ICupBracket;
  seedDetail?: ISeedProps;
  isPopupOpen: boolean;
  closePopup: () => void;
  showAccountInfo: (officeName: string) => void;
  showRepInfo: (officeName: string) => void;
}

const TheCupBracketInfoPopup = ({
  bracketDetail,
  seedDetail,
  isPopupOpen,
  closePopup,
  showRepInfo,
  showAccountInfo,
}: TheCupBracketInfoPopupProps) => {
  return (
    <MobilePopup
      isOpen={isPopupOpen}
      onClose={closePopup}
      backBg="bg-overlay"
      popupBg="bg-thecup-secondary-main"
      popupBorder="ring-0px"
      position={MobilePopupPosition.Bottom}
    >
      <div className="flex flex-col gap-m">
        <div className="flex flex-col items-center justify-between">
          <div className="flex flex-col w-full gap-1 bg-white bg-opacity-50 pt-3 pb-[15px] px-4 rounded-t-[10px] relative">
            <div className="flex w-full justify-between items-center py-[5px]">
              <h2 className="text-thecup-secondary-text text-center w-full font-plus text-base not-italic font-bold leading-[150%]">
                {`${bracketDetail?.competitionName} - ${bracketDetail?.matchName}`}
              </h2>
            </div>

            <h3 className="text-thecup-secondary-text text-center text-opacity-60 font-plus text-sm not-italic font-semibold leading-[150%]">
              {`${formatDateString(
                bracketDetail?.matchStart || ""
              )} ~ ${formatDateString(bracketDetail?.matchEnd || "")}`}
            </h3>

            <IconButton
              onClick={closePopup}
              className="absolute top-[20px] left-[20px]"
            >
              <ArrowLeftBack className="text-thecup-secondary-text" />
            </IconButton>
          </div>

          <div className="grid grid-cols-2 px-6 py-[22px] w-full justify-between">
            <div className="flex flex-col gap-4 w-full">
              <div className="flex items-center w-min justify-center text-white font-plus text-[32px] not-italic font-bold leading-[150%] bg-thecup-midnightDepth bg-opacity-35 px-[10px] py-2 rounded-[12px]">
                {seedDetail?.teams[0].score}
              </div>

              <div className="flex flex-col gap-3 items-start">
                <div className="flex items-center gap-2 w-full">
                  <div className="text-thecup-midnightDepth font-plus text-base not-italic font-bold leading-[150%] whitespace-nowrap overflow-hidden overflow-ellipsis">
                    {bracketDetail?.officeName}
                  </div>
                  <div>
                    {seedDetail?.teams[0].score >
                      seedDetail?.teams[1].score && (
                      <TheCupWinnerBadgeIcon className="h-[20px] w-[15px] text-thecup-primary-main" />
                    )}
                  </div>
                </div>

                <div
                  className="flex items-center gap-[10px] bg-white rounded-[8px] px-3 py-[6px] active:bg-opacity-50 transition"
                  onClick={() => {
                    showRepInfo(bracketDetail?.officeName || "");
                    closePopup();
                  }}
                >
                  <div className="text-thecup-midnightDepth font-plus text-sm not-italic font-semibold leading-[150%] select-none">
                    Rep details
                  </div>
                  <ArrowRightButtonIcon className="text-black" />
                </div>

                <div
                  className="flex items-center gap-[10px] bg-white rounded-[8px] px-3 py-[6px] active:bg-opacity-50 transition"
                  onClick={() => {
                    showAccountInfo(bracketDetail?.officeName || "");
                    closePopup();
                  }}
                >
                  <div className="text-thecup-midnightDepth font-plus text-sm not-italic font-semibold leading-[150%] select-none">
                    Account details
                  </div>
                  <ArrowRightButtonIcon className="text-black" />
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-4 w-full items-end">
              <div className="flex items-center w-min justify-center text-white font-plus text-[32px] not-italic font-bold leading-[150%] bg-thecup-midnightDepth px-[10px] py-2 rounded-[12px]">
                {seedDetail?.teams[1].score}
              </div>

              <div className="flex flex-col gap-3 items-end w-full">
                <div className="flex items-center gap-2 w-full justify-end">
                  <div>
                    {seedDetail?.teams[0].score <
                      seedDetail?.teams[1].score && (
                      <TheCupWinnerBadgeIcon className="h-[20px] w-[15px] text-thecup-primary-main" />
                    )}
                  </div>
                  <div className="text-thecup-midnightDepth font-plus text-base not-italic font-bold leading-[150%] whitespace-nowrap overflow-hidden overflow-ellipsis">
                    {bracketDetail?.opponent}
                  </div>
                </div>

                <div
                  className="flex items-center gap-[10px] bg-white rounded-[8px] px-3 py-[6px] active:bg-opacity-50 transition"
                  onClick={() => {
                    showRepInfo(bracketDetail?.opponent || "");
                    closePopup();
                  }}
                >
                  <div className="text-thecup-midnightDepth font-plus text-sm not-italic font-semibold leading-[150%] select-none">
                    Rep details
                  </div>
                  <ArrowRightButtonIcon className="text-black" />
                </div>

                <div
                  className="flex items-center gap-[10px] bg-white rounded-[8px] px-3 py-[6px] active:bg-opacity-50 transition"
                  onClick={() => {
                    showAccountInfo(bracketDetail?.opponent || "");
                    closePopup();
                  }}
                >
                  <div className="text-thecup-midnightDepth font-plus text-sm not-italic font-semibold leading-[150%] select-none">
                    Account details
                  </div>
                  <ArrowRightButtonIcon className="text-black" />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col px-6  w-full">
            <div className="border-[1px] rounded-[6px] border-solid border-viper-mediumDarkGrayishBlue bg-[#252838]">
              <div className="font-work px-2 py-[10px] border-b-[1px] border-solid border-viper-mediumDarkGrayishBlue text-center text-viper-softGrayishBlue text-[13px] not-italic font-bold uppercase">
                Baseline Rep Score
              </div>

              <div className="flex gap-1 px-2 py-[10px] items-center justify-center border-b-[1px] border-solid border-viper-mediumDarkGrayishBlue bg-viper-darkGrayishBlue">
                <div className="text-white text-center font-work text-[13px] not-italic font-bold uppercase width-20">
                  {seedDetail?.teams[0].baselineScore}
                </div>
                <div className="text-viper-softGrayishBlue text-center font-work text-[13px] not-italic font-bold uppercase width-20">
                  VS
                </div>
                <div className="text-white text-center font-work text-[13px] not-italic font-bold uppercase width-20">
                  {seedDetail?.teams[1].baselineScore}
                </div>
              </div>

              <div className="font-work px-2 py-[10px] border-b-[1px] border-solid border-viper-mediumDarkGrayishBlue text-center text-viper-softGrayishBlue text-[13px] not-italic font-bold uppercase">
                Match rep score
              </div>

              <div className="flex gap-1 px-2 py-[10px] items-center justify-center border-b-[1px] border-solid border-viper-mediumDarkGrayishBlue bg-viper-darkGrayishBlue">
                <div className="text-white text-center font-work text-[13px] not-italic font-bold uppercase width-20">
                  {seedDetail?.teams[0].matchScore}
                </div>
                <div className="text-viper-softGrayishBlue text-center font-work text-[13px] not-italic font-bold uppercase width-20">
                  VS
                </div>
                <div className="text-white text-center font-work text-[13px] not-italic font-bold uppercase width-20">
                  {seedDetail?.teams[1].matchScore}
                </div>
              </div>

              <div className="font-work px-2 py-[10px] border-b-[1px] border-solid border-viper-mediumDarkGrayishBlue text-center text-viper-softGrayishBlue text-[13px] not-italic font-bold uppercase">
                Difference
              </div>

              <div className="flex gap-1 px-2 py-[10px] items-center justify-center bg-viper-darkGrayishBlue">
                <div className="text-white text-center font-work text-[13px] not-italic font-bold uppercase width-20">
                  {seedDetail?.teams[0].score}
                </div>
                <div className="text-viper-softGrayishBlue text-center font-work text-[13px] not-italic font-bold uppercase width-20">
                  VS
                </div>
                <div className="text-white text-center font-work text-[13px] not-italic font-bold uppercase width-20">
                  {seedDetail?.teams[1].score}
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-3 px-6 w-full my-4">
            {prizes.map((prize) => (
              <div
                key={prize.name}
                className="flex items-center gap-4 bg-thecup-darkGrayishBlue rounded-[15px] px-[16px] py-[15px]"
              >
                <img
                  src={prize.image}
                  alt={prize.name}
                  className="w-[50px] h-[50px] rounded-[50%]"
                />
                <div className="flex flex-col justify-between">
                  <div className="font-plus text-white text-opacity-75 text-[13px] not-italic font-semibold leading-[150%]">
                    Competing for
                  </div>
                  <div className="font-plus text-white text-base not-italic font-bold leading-[150%]">
                    {prize.name}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </MobilePopup>
  );
};

export default TheCupBracketInfoPopup;
