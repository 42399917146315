import { format, startOfYear } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import Loading from "react-fullscreen-loading";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { PageType } from "../../const/enums/PageType";
import { IProfile } from "../../interfaces/profile";
import { setCurrentPage } from "../../redux/slices/globalSlice";
import axiosInstance from "../../services/axiosInstance";
import MainProfile from "./MainProfile";
import PersonalStats from "./PersonalStats";
import ProfileBreadcrumb from "./ProfileBreadcrumb";
import ProfileTabs from "./ProfileTabs";
import RepBoard from "./RepBoard";
import SearchModal from "./SearchModal";

export default function ProfileStats() {
  const [openNav, setOpenNav] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const navigate = useNavigate();
  const [profile, setProfile] = useState<IProfile>();
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [stats, setStats] = useState();
  const [rankBoard, setRankBoard] = useState();
  const dispatch = useDispatch();

  const dependencies = [
    searchParams.get("name"),
    searchParams.get("category"),
    searchParams.get("start_date"),
    searchParams.get("end_date"),
  ];

  const profileQuery = useMemo(
    () => ({
      name: searchParams.get("name"),
      category: searchParams.get("category") || "",
      start_date:
        searchParams.get("start_date") ||
        format(startOfYear(new Date()), "yyyy-MM-dd"),
      end_date:
        searchParams.get("end_date") || format(new Date(), "yyyy-MM-dd"),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies
  );

  const rankBoardQuery = useMemo(
    () => ({
      ...profileQuery,
      order_by: searchParams.get("order_by") ?? "sales",
      order_dir: searchParams.get("order_dir") ?? "asc",
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profileQuery, searchParams.get("order_by"), searchParams.get("order_dir")]
  );

  const fetchRankBoard = async () => {
    const { data } = await axiosInstance.get("/profile/rank_board", {
      params: rankBoardQuery,
    });
    setRankBoard(data);
  };

  const fetchProfile = async () => {
    setLoading(true);

    try {
      const { data } = await axiosInstance.get(`/profile`, {
        params: profileQuery,
      });
      if (!data) {
        navigate("/");
      }
      setProfile(data);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  const fetchStats = async () => {
    try {
      const { data } = await axiosInstance.get(`/profile/stats`, {
        params: profileQuery,
      });
      setStats(data);
      const stats = data
        .map((item: any) => {
          const newItem: any = {
            category: item.selected_entity_level,
            name: item.entity_value,
            fields: {},
          };

          Object.keys(item).forEach((key) => {
            if (
              [
                "selected_entity_level",
                "entity_value_parent_1",
                "entity_value",
              ].includes(key)
            ) {
              return;
            }

            if (key.includes("rank")) {
              newItem.fields[key.replace("_rank", "")].rank = item[key];
            } else {
              newItem.fields[key] = {
                label: key
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" "),
                value: item[key],
                rank: "",
              };
            }
          });

          return newItem;
        })
        .reduce((mapping: any, item: any) => {
          if (item.category === searchParams.get("category")) {
            mapping[item.category] = item;
            return mapping;
          }

          if (!mapping[item.category]) {
            mapping[item.category] = [];
          }
          mapping[item.category].push(item);
          return mapping;
        }, {} as any);
      setStats(stats);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!profileQuery.category || !profileQuery.name) {
      return navigate("/");
    }
    fetchProfile();
    fetchStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileQuery]);

  useEffect(() => {
    if (!rankBoardQuery.category || !rankBoardQuery.name) {
      return;
    }
    fetchRankBoard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rankBoardQuery]);

  useEffect(() => {
    dispatch(setCurrentPage(PageType.Profile));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pb-20">
      <ProfileBreadcrumb onToggleNav={() => setOpenNav(true)} />
      {!loading && profile ? (
        <>
          <MainProfile profile={profile} />
          <ProfileTabs currentTab={currentTab} onSwitchTab={setCurrentTab} />
          {currentTab === 0 ? (
            <PersonalStats stats={stats} />
          ) : (
            <RepBoard
              mainProfile={stats?.[profileQuery.category]}
              board={rankBoard ?? []}
              orderBy={rankBoardQuery.order_by}
              orderDir={rankBoardQuery.order_dir}
            />
          )}
        </>
      ) : (
        <Loading />
      )}
      <SearchModal open={openNav} onClose={() => setOpenNav(false)} />
    </div>
  );
}
