import { useEffect, useState } from "react";
import { ISeedProps } from "react-brackets";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  getCupBracketListAction,
  updateCurrentDetailInfo,
} from "../../../redux/slices/cupSlice";
import { generateCupBracketRounds } from "./helper/bracket";

import { ICupBracket } from "../../../interfaces/cupBracket";
import TheCupBracketInfoPopup from "./Brackets/TheCupBracketInfoPopup";
import TheCupBrackets from "./Brackets/TheCupBrackets";
import TheCupSearchBar from "./Brackets/TheCupSearchBar";
import TheCupDivisionList from "./Common/TheCupDivisionList";

export interface TheCupBracketsTabProps {
  showingRepDetails: boolean;
  showRepDetails: (showingRepDetails: boolean) => void;

  showingAccountDetails: boolean;
  showAccountDetails: (showingAccountDetails: boolean) => void;
}

const TheCupBracketsTab = ({
  showAccountDetails,
  showRepDetails,
  showingAccountDetails,
  showingRepDetails,
}: TheCupBracketsTabProps) => {
  const dispatch = useAppDispatch();

  const [showBracketDetail, setShowBracketDetail] = useState(false);
  const [bracketDetail, setBracketDetail] = useState<ICupBracket>();
  const [seedDetail, setSeedDetail] = useState<ISeedProps>();

  const { currentDivision, bracketList, isLoadingBracketList } = useAppSelector(
    (state) => state.cup
  );

  useEffect(() => {
    dispatch(getCupBracketListAction(currentDivision));
  }, [currentDivision, dispatch]);

  return (
    <div className="flex flex-col gap-4 mt-2 border-t-[1px] border-solid border-thecup-secondary-main border-opacity-50">
      <div className="flex flex-col px-5 pt-6 gap-4">
        <TheCupSearchBar />

        <TheCupDivisionList />
      </div>

      {isLoadingBracketList ? (
        <div className="m-auto flex items-center justify-between mt-10">
          <div className="w-8 h-8 border-2 border-t-thecup-primary-main border-gray-300 rounded-full animate-spin"></div>
        </div>
      ) : (
        <div className="flex flex-col w-full">
          <TheCupBrackets
            rounds={generateCupBracketRounds(bracketList)[0]}
            brackets={bracketList}
            setBracketDetail={(bracket: ICupBracket) => {
              setShowBracketDetail(true);
              setBracketDetail(bracket);
            }}
            setSeedDetail={(seed: ISeedProps) => {
              setSeedDetail(seed);
            }}
          />

          <TheCupBrackets
            isHome={true}
            rounds={generateCupBracketRounds(bracketList)[1]}
            brackets={bracketList}
            setBracketDetail={(bracket: ICupBracket) => {
              setShowBracketDetail(true);
              setBracketDetail(bracket);
            }}
            setSeedDetail={(seed: ISeedProps) => {
              setSeedDetail(seed);
            }}
          />
        </div>
      )}

      <TheCupBracketInfoPopup
        bracketDetail={bracketDetail}
        seedDetail={seedDetail}
        isPopupOpen={showBracketDetail}
        closePopup={() => setShowBracketDetail(false)}
        showAccountInfo={(officeName: string) => {
          showAccountDetails(true);
          dispatch(
            updateCurrentDetailInfo({
              officeName,
              division: currentDivision,
              matchName: bracketDetail?.matchName,
            })
          );
        }}
        showRepInfo={(officeName: string) => {
          showRepDetails(true);
          dispatch(
            updateCurrentDetailInfo({
              officeName,
              division: currentDivision,
              matchName: bracketDetail?.matchName,
            })
          );
        }}
      />
    </div>
  );
};

export default TheCupBracketsTab;
