import classNames from "classnames";
import { ISeedProps, SeedItem } from "react-brackets";
import TheCupWinnerBadgeIcon from "../../../icons/TheCupWinnerBadgeIcon";

interface TheCupBracketProps {
  seed: ISeedProps;
}

const TheCupBracket = ({ seed }: TheCupBracketProps) => {
  const homeTeam = seed.teams[0];
  const awayTeam = seed.teams[1];

  return (
    <div className={"!py-[17px] !pl-[0px] mr-[6px]"}>
      <SeedItem className="flex !bg-transparent !shadow-none items-center gap-[6px] justify-between pr-[32px]">
        <div className="flex flex-col gap-[3px] w-full overflow-x-hidden">
          <div className="flex justify-between bg-thecup-shadowedSlate w-full h-[40px] rounded-t-[6px] border-t-[1px] border-b-[1px] border-solid border-thecup-darkGrayishBlue box-content">
            <div className="select-none whitespace-nowrap overflow-hidden overflow-ellipse flex gap-2 items-center text-white font-plus py-[7px] px-[15px] text-[14px] not-italic font-medium leading-[150%]">
              {homeTeam.name ? homeTeam.name : "--"}

              {homeTeam.isWinner && (
                <TheCupWinnerBadgeIcon className="h-[20px] w-[15px] text-thecup-primary-main" />
              )}
            </div>

            <div
              className={classNames(
                "items-center flex justify-center w-[94px] h-[40px] select-none rounded-tr-[6px] text-white font-plus text-sm not-italic font-medium leading-[150%]",
                {
                  "bg-thecup-primary-main": homeTeam.isWinner,
                  "bg-thecup-stormyGray": !homeTeam.isWinner,
                }
              )}
            >
              {homeTeam.score}
            </div>
          </div>

          <div className="flex justify-between bg-thecup-shadowedSlate w-full h-[40px] rounded-b-[6px] border-t-[1px] border-b-[1px] border-solid border-thecup-darkGrayishBlue right box-content">
            <div className="select-none whitespace-nowrap overflow-hidden overflow-ellipse flex gap-2 items-center text-white font-plus py-[7px] px-[15px] text-[14px] not-italic font-medium leading-[150%]">
              {awayTeam.name ? awayTeam.name : "--"}

              {awayTeam.isWinner && (
                <TheCupWinnerBadgeIcon className="h-[20px] w-[15px] text-thecup-primary-main" />
              )}
            </div>

            <div
              className={classNames(
                "items-center flex justify-center w-[94px] h-[40px] select-none rounded-br-[6px] text-white font-plus text-sm not-italic font-medium leading-[150%]",
                {
                  "bg-thecup-primary-main": awayTeam.isWinner,
                  "bg-thecup-stormyGray": !awayTeam.isWinner,
                }
              )}
            >
              {awayTeam.score}
            </div>
          </div>
        </div>
      </SeedItem>
    </div>
  );
};

export default TheCupBracket;
