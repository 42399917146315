const ViperBattleIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      className={className}
    >
      <path
        d="M11.504 22L13.0927 14.8006H10L11.2357 6H15.9837L14.2012 11.6076H18L11.504 22Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ViperBattleIcon;
