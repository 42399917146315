import { useEffect, useState } from "react";
import { ISeedProps } from "react-brackets";
import { CSSTransition } from "react-transition-group";

import { IViperBracketData } from "../../../interfaces/viperBracketData";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getBracketDataAction } from "../../../redux/slices/viperSlice";

import ViperBracketInfo from "./Brackets/ViperBracketInfo";
import ViperBrackets from "./Brackets/ViperBrackets";
import ViperDivisionLists from "./Common/ViperDivisionList";
import ViperPrizes from "./Common/ViperPrizes";

const ViperBracketsTab = () => {
  const dispatch = useAppDispatch();

  const [showBracketDetail, setShowBracketDetail] = useState(false);
  const [bracketDetail, setBracketDetail] = useState<IViperBracketData>();
  const [seedDetail, setSeedDetail] = useState<ISeedProps>();

  const { currentDivision, viperBracketData } = useAppSelector(
    (state) => state.viper
  );

  useEffect(() => {
    dispatch(
      getBracketDataAction({
        division: currentDivision,
      })
    );
  }, [currentDivision, dispatch]);

  return (
    <>
      <CSSTransition
        in={showBracketDetail}
        timeout={200}
        classNames={{
          enter: "fade-enter",
          enterActive: "fade-enter-active",
          exit: "fade-exit",
          exitActive: "fade-exit-active",
        }}
        unmountOnExit
      >
        <ViperBracketInfo
          bracketDetail={bracketDetail}
          seedDetail={seedDetail}
          onBack={() => {
            setShowBracketDetail(false);
          }}
        />
      </CSSTransition>

      <CSSTransition
        in={!showBracketDetail}
        timeout={200}
        classNames={{
          enter: "fade-enter",
          enterActive: "fade-enter-active",
          exit: "fade-exit",
          exitActive: "fade-exit-active",
        }}
        unmountOnExit
      >
        <div>
          <ViperDivisionLists />

          <ViperBrackets
            brackets={viperBracketData}
            setBracketDetail={(bracket: IViperBracketData) => {
              setShowBracketDetail(true);
              setBracketDetail(bracket);
            }}
            setSeedDetail={(seed: ISeedProps) => {
              setSeedDetail(seed);
            }}
          />

          <ViperPrizes prizeCount={3} />
        </div>
      </CSSTransition>
    </>
  );
};

export default ViperBracketsTab;
