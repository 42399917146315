import classNames from "classnames";
import isMobile from "is-mobile";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { getCupRepDetailsAction } from "../../../../redux/slices/cupSlice";

interface TheCupRepDetailsProps {
  officeName: string;
  division: string;
  matchName: string;
}

const tableFields = [
  "Rep Name",
  "In",
  "RS",
  "TRMR",
  "RMR PTS",
  "VPP PTS",
  "PKG PTS",
  "L PTS",
];

const TheCupRepDetails = ({
  officeName,
  division,
  matchName,
}: TheCupRepDetailsProps) => {
  const dispatch = useAppDispatch();

  const rowIncrement = isMobile() ? 30 : 40; // Number of rows to load on each scroll
  const [visibleRows, setVisibleRows] = useState(rowIncrement); // Start with 20 rows
  const { repDetails, isLoadingRepDetails } = useAppSelector(
    (state) => state.cup
  );

  useEffect(() => {
    dispatch(getCupRepDetailsAction(officeName));
  }, [dispatch, officeName]);

  // Handle scroll event to trigger lazy loading
  const handleScroll = () => {
    const table = document.querySelector(".rep-table");
    if (table) {
      const { scrollTop, scrollHeight, clientHeight } = table;
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        loadMoreRows();
      }
    }
  };

  const loadMoreRows = () => {
    if (visibleRows < repDetails.length) {
      setVisibleRows((prev) =>
        Math.min(prev + rowIncrement, repDetails.length)
      );
    }
  };

  useEffect(() => {
    const table = document.querySelector(".rep-table");
    table?.addEventListener("scroll", handleScroll);
    return () => {
      table?.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleRows, repDetails]);

  useEffect(() => {
    setVisibleRows(rowIncrement);
  }, [rowIncrement, repDetails]);

  return (
    <div className="flex flex-col gap-2 px-5">
      <div className="flex flex-col gap-1">
        <div className="font-primary text-[24px] font-bold">{officeName}</div>
        <div className="text-sm font-semibold">
          {division} - {matchName}
        </div>
      </div>

      <div className="rep-table w-full overflow-x-auto scrollbar-thin scrollbar-thumb-[#a1a6c0] scrollbar-track-[#1a1c2e] scrollbar-thumb-rounded-full scrollbar-track-rounded-full max-h-thecup-detail">
        <div className="min-w-[720px] bg-[#484C5EA6] flex border-[1px] border-solid border-viper-mediumDarkGrayishBlue rounded-t-[6px]">
          <div className=" w-10 border-r-[1px] border-solid border-viper-mediumDarkGrayishBlue"></div>

          {tableFields.map((field, index) => {
            return (
              <div
                className={classNames(`text-center whitespace-nowrap  `, {
                  "grow-[1]": index === 0,
                  "w-[72px]": index > 0,
                  "border-r-[1px] border-solid border-viper-mediumDarkGrayishBlue":
                    index !== tableFields.length - 1,
                })}
                key={index}
              >
                <div className="py-[10px] font-plus text-[13px] not-italic font-semibold leading-[150%]">
                  {field}
                </div>
              </div>
            );
          })}
        </div>

        <div className="mt-[6px] pb-[14px]">
          {isLoadingRepDetails
            ? [...new Array(rowIncrement)].map((_, index) => {
                return (
                  <div
                    className={classNames(
                      "min-w-[720px] h-[41px] bg-[#2F324333] flex border-[1px] border-solid border-viper-mediumDarkGrayishBlue",
                      { "border-b-[0px]": index !== 10 - 1 }
                    )}
                    key={index}
                  >
                    <div className="w-10 text-center border-r-[1px] border-solid border-viper-mediumDarkGrayishBlue whitespace-nowrap">
                      <div className="py-[10px] font-plus text-[13px] not-italic font-normal leading-[150%]">
                        {index + 1}
                      </div>
                    </div>
                    {tableFields.map((field, index) => {
                      return (
                        <div
                          className={classNames(
                            `text-center whitespace-nowrap  `,
                            {
                              "grow-[1]": index === 0,
                              "w-[72px]": index > 0,
                              "border-r-[1px] border-solid border-viper-mediumDarkGrayishBlue":
                                index !== tableFields.length - 1,
                            }
                          )}
                          key={index}
                        >
                          <div className="py-[6px] h-[41px] font-plus text-[13px] not-italic font-normal">
                            <Skeleton
                              width={index === 0 ? 120 : 60}
                              height={21}
                              baseColor="#2F324366"
                              highlightColor="#2F3243"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })
            : repDetails.slice(0, visibleRows).map((rep, index) => {
                const repData = [
                  rep.rep,
                  rep.in,
                  rep.rs,
                  rep.trmr,
                  rep.rmrPts,
                  rep.vppPts,
                  rep.pkgPts,
                  rep.lPts,
                ];

                return (
                  <div
                    className={classNames(
                      "min-w-[720px] bg-[#2F324333] flex border-[1px] border-solid border-viper-mediumDarkGrayishBlue",
                      "bg-[#2F324330] border-b-[1px]",
                      {
                        "border-b-[0px]": index !== repDetails.length - 1,
                      }
                    )}
                    key={index}
                  >
                    <div className="w-10 text-center border-r-[1px] border-solid border-viper-mediumDarkGrayishBlue whitespace-nowrap">
                      <div className="py-[10px] font-plus text-[13px] not-italic font-normal leading-[150%]">
                        {index + 1}
                      </div>
                    </div>

                    {
                      // Render the rep data
                      repData.map((data, index) => {
                        return (
                          <div
                            className={classNames(
                              `text-center whitespace-nowrap  `,
                              {
                                "grow-[1]": index === 0,
                                "w-[72px]": index > 0,
                                "border-r-[1px] border-solid border-viper-mediumDarkGrayishBlue":
                                  index !== repData.length - 1,
                              }
                            )}
                            key={index}
                          >
                            <div className="py-[10px] px-[10px] font-plus text-[13px] not-italic font-semibold leading-[150%] whitespace-nowrap overflow-hidden overflow-ellipsis">
                              {typeof data === "number"
                                ? parseFloat(data.toString()).toFixed(2)
                                : data}
                            </div>
                          </div>
                        );
                      })
                    }
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default TheCupRepDetails;
