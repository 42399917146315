import classNames from "classnames";
import { ViperDivisionList } from "../../../../data/Viper";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { updateCurrentDivision } from "../../../../redux/slices/viperSlice";

const BracketsTeamList = () => {
  const dispatch = useAppDispatch();
  const { currentDivision } = useAppSelector((state) => state.viper);

  const updateSelectedDivision = (division: string) => {
    dispatch(updateCurrentDivision(division));
  };

  return (
    <div className="flex gap-3 px-6 w-full pt-[18px] pb-2 overflow-auto scrollbar-thin scrollbar-thumb-[#a1a6c0] scrollbar-track-[#1a1c2e] scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
      {ViperDivisionList.map((division, index) => {
        return (
          <div
            className={classNames(
              "px-[14px] py-[6.5px] transition rounded-[10px] cursor-pointer font-medium leading-[120%] text-base not-italic text-white whitespace-nowrap font-work",
              {
                "bg-viper-primary": currentDivision === division,
                "bg-viper-darkGrayishBlue": currentDivision !== division,
              }
            )}
            onClick={() => {
              updateSelectedDivision(division);
            }}
            key={index}
          >
            {division}
          </div>
        );
      })}
    </div>
  );
};

export default BracketsTeamList;
