const ViperIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="58"
      height="59"
      viewBox="0 0 58 59"
      fill="none"
      className={className}
    >
      <path
        d="M51.7901 25.7762L45.5459 33.7156L50.5413 21.8843L52.8048 24.4529L57.488 23.8302L57.6441 28.0335L52.1804 34.0269L56.9416 32.7815L51.3218 38.0745L48.668 35.0388L55.0683 27.0216L51.7901 25.7762Z"
        fill="currentColor"
      />
      <path
        d="M43.0686 22.5972L38.0323 25.2248L33.6255 37.0493L35.9076 39.677L44.5638 33.4942L37.5601 35.7355L39.0553 32.2577L45.9016 28.4708V26.2295L43.0686 22.5972Z"
        fill="currentColor"
      />
      <path
        d="M32.2774 29.3398L25.496 35.2753L34.7717 30.8237V27.2312L31.1861 23.4824L25.7299 24.8882L19.2603 44.1786L11.6216 45.1157L10.1406 48.8645L18.1691 47.3806L15.1292 56.7524L19.9619 46.912L57.9996 39.4145L21.4428 43.7881L29.3154 27.2312L32.2774 29.3398Z"
        fill="currentColor"
      />
      <path
        d="M22.2392 24.9111L18.5628 25.6112L13.1655 39.3793L15.6686 41.6351L21.6134 36.968L16.8419 38.5237L22.2392 24.9111Z"
        fill="currentColor"
      />
      <path
        d="M24.9082 18.3223L21.1406 19.1098L19.5708 23.126L23.0244 22.3385L24.9082 18.3223Z"
        fill="currentColor"
      />
      <path
        d="M10.7646 11.3208L0.390023 16.6298L0 58.7118L25.9755 0L4.29025 40.0521L10.7646 11.3208Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ViperIcon;
