import { SheetCategory } from "../../const/enums/HomeCompetitionCardType";
import { IFavorite } from "../../interfaces/favorite";
import { IFilterList } from "../../interfaces/filterList";
import { IHomeCard } from "../../interfaces/homeSheet";
import { ReportDataInfo } from "../../interfaces/reportDataInfo";
import { IReportList } from "../../interfaces/reportList";
import { IReportView, IReportViewSheet } from "../../interfaces/reportView";
import { ITrending } from "../../interfaces/trending";
import { IPhoto } from "./../../interfaces/photo";
import { IReportFilterView } from "./../../interfaces/reportFilterView";

const parseReports = (payload: any[]) => {
  return payload.map((report, index) => {
    return {
      id: report.id,
      reportName: report.report_name,
      alias: report.alias,
      reportType: report.report_type,
      category: report.category,
      reportUrl: report.report_url,
      position: report.position,
      isCompetition: report.is_competition,
      imageUrl: report.image_url,
      tutorialVideoLink: report.tutorial_video_link,
      isFeaturedCompetition: report.is_featured_competition,
      reportFields: report.report_fields,
    };
  });
};

// Function to group reports by category name
const groupReportsByCategory = (reports: any[]): IReportList[] => {
  return Object.values<IReportList>(
    reports.reduce((acc, report) => {
      const categoryName = report.category.name;

      if (!acc[categoryName]) {
        acc[categoryName] = {
          id: report.category.id,
          categoryName,
          isCompetition: report.category.is_competition,
          imageUrl: report.category.image_url,
          position: report.category.position,
          reports: [],
        };
      }

      acc[categoryName].reports.push(report);

      return acc;
    }, {})
  )
    .sort((c1, c2) => c1.position - c2.position)
    .map((c) => ({
      ...c,
      reports: c.reports.sort((r1, r2) => r1.position - r2.position),
    }));
};

const parseFilterData = (filterData: {
  [key: string]: (string | null)[];
}): IFilterList => {
  const parsedData: IFilterList = {};

  // Loop through each key in the filterData object
  for (const key in filterData) {
    if (filterData.hasOwnProperty(key)) {
      // Replace null values with 'NULL'
      parsedData[key] = filterData[key].map((value) =>
        value === null ? "NULL" : value
      );
    }
  }

  return parsedData;
};

const parseReportViewInfoData = (reportName: string, reportView: any) => {
  return reportView.map((view: any) => {
    return {
      reportName: reportName,
      viewName: view.view_name,
      viewSheets: view.view_sheets.map((sheet: any) => {
        return {
          sheetType: sheet.sheet_type,
          sheetField: sheet.sheet_field,
        } as IReportViewSheet;
      }),
    } as IReportView;
  });
};

const parseReportDataInfo = (reportData: any) => {
  return {
    name: reportData.report_name,
    filterDefaultGroupBy: reportData.report_default_group_by,
    filterHierarchy: reportData.report_filter_hierarchy,
    filterAdditional: reportData.report_filter_additional,
    filterGroupNames: reportData.report_filter_group_names,
    filterGroupFields: reportData.report_filter_group_fields,
    tableColumns: reportData.report_table_fields.map(
      (field: string, index: number) => ({
        field,
        name: reportData.report_table_field_names[index],
      })
    ),
    view: parseReportViewInfoData(
      reportData.report_name,
      reportData.report_views
    ),
  } as ReportDataInfo;
};

const parsePhotos = (photos: any) => {
  return photos.map((photo: any) => {
    return {
      id: photo.id,
      category: photo.category,
      recordId: parseInt(photo.record_id),
      link: photo.cloudinary_url,
    } as IPhoto;
  });
};

const parseFavorites = (favorites: any) => {
  return favorites.map((favorite: any) => {
    return {
      id: favorite.id,
      favoriteId: favorite.favorite_id,
      favoriteType: favorite.favorite_type,
    } as IFavorite;
  });
};

const parseReportFilterViews = (filterViews: any) => {
  return filterViews.map((filterView: any) => {
    return {
      id: filterView.id,
      userId: filterView.user_id,
      filters: filterView.filters,
      fields: filterView.fields,
      createdAt: filterView.created_at,
      viewName: filterView.view_name,
      selected: filterView.selected,
    } as IReportFilterView;
  });
};

const parseTrendingInfos = (trendings: any[]) => {
  return trendings.map((trending) => {
    return {
      title: trending.HEADER1,
      subTitle: trending.HEADER2,
      description: trending.HEADER3,
      entityMetric: trending.ENTITY_METRIC,
      photo: trending.PHOTO,
    } as ITrending;
  });
};

const parseHomeSheets = (homeSheets: any[]) => {
  return homeSheets.map((homeSheet) => {
    return {
      id: homeSheet.id,
      sheetCategory: SheetCategory.Report,
      position: homeSheet.position,
      reportId: parseInt(homeSheet.report_id),
      reportName: homeSheet.report.report_name,
      reportAlias: homeSheet.report.alias,
      reportFilterViewName: homeSheet.report_filter_view_name,
      reportViewName: homeSheet.report_view_name,
      sheetType: homeSheet.sheet_type,
      sheetField: homeSheet.sheet_field,
      reportFilters: homeSheet.report_filters,
      reportFields: homeSheet.report_fields,
    } as IHomeCard;
  });
};

const parseHomeCompetitionCards = (homeCompetitionCards: any[]) => {
  return homeCompetitionCards.map((card) => {
    return {
      id: card.id,
      sheetCategory: SheetCategory.Competition,
      position: card.position,
      cardName: card.card_name,
      cardType: card.card_type,
      cardParams: card.card_params
    } as IHomeCard;
  });
};

export {
  groupReportsByCategory,
  parseFavorites,
  parseFilterData,
  parsePhotos,
  parseReportDataInfo,
  parseReportFilterViews,
  parseReports,
  parseTrendingInfos,
  parseHomeSheets,
  parseHomeCompetitionCards
};
