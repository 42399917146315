import { useState } from "react";
import { useAppSelector } from "../../redux/hooks";

import { IReportList } from "../../interfaces/reportList";

import EditFavoritePopup from "../Home/EditFavoritePopup";
import Competitions from "./Competitions";
import Favorites from "./Favorites";
import ReportLists from "./ReportLists";
import SearchBar from "./SearchBar";

interface SideMenuProps {
  isMenuOpen: boolean;
  closeMenu: () => void;
}

const SideMenu = ({ isMenuOpen, closeMenu }: SideMenuProps) => {
  const { reports, featuredCompetitions } = useAppSelector(
    (state) => state.report
  );
  const [searchResult, setSearchResult] = useState<IReportList[] | null>();
  const [isEditFavoriteOpen, setIsEditFavoriteOpen] = useState(false);

  const handleSearch = (search: string) => {
    if (!search) {
      setSearchResult(null);
      return;
    }
    const result = reports
      .map((r) => ({
        ...r,
        reports: r.reports.filter((report) =>
          report.reportName.toLowerCase().includes(search.toLowerCase())
        ),
      }))
      .filter((r) => r.reports.length > 0);

    setSearchResult(result);
  };

  return (
    <div
      className={`fixed top-[52px] w-full h-full transition duration-300 ease-in-out z-30 max-h-fit-height ${
        isMenuOpen
          ? "opacity-100 scale-100 translate-x-[0%]"
          : "opacity-0 scale-95 translate-x-[-20%] pointer-events-none"
      }`}
    >
      <div className="flex flex-col gap-[17px] flex-grow-1 h-full z-50 bg-custom-grey-dd overflow-y-scroll pt-[12px] pb-[72px]">
        <SearchBar onSearch={handleSearch} />

        {searchResult ? (
          <ReportLists open reports={searchResult} closeMenu={closeMenu} />
        ) : (
          <>
            <Favorites
              closeMenu={closeMenu}
              onEditFavorites={() => setIsEditFavoriteOpen(true)}
            />

            <Competitions
              competitionName="Competitions"
              competitions={featuredCompetitions}
              onClose={closeMenu}
            />

            <ReportLists
              reports={reports.filter((r) => !r.isCompetition)}
              closeMenu={closeMenu}
            />

            <EditFavoritePopup
              isPopupOpen={isEditFavoriteOpen}
              closePopup={() => setIsEditFavoriteOpen(false)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default SideMenu;
