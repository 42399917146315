const ArrowPageRight = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="14"
      viewBox="0 0 9 14"
      fill="none"
      className={className}
    >
      <path
        d="M8.29475 6.39018L2.23244 0.327982C2.09223 0.187657 1.90506 0.110352 1.70548 0.110352C1.5059 0.110352 1.31873 0.187657 1.17851 0.327982L0.732068 0.774318C0.441562 1.06516 0.441562 1.53785 0.732068 1.82825L5.82274 6.91892L0.726419 12.0152C0.586206 12.1556 0.508789 12.3426 0.508789 12.5421C0.508789 12.7418 0.586206 12.9288 0.726419 13.0693L1.17287 13.5155C1.31319 13.6558 1.50025 13.7331 1.69983 13.7331C1.89941 13.7331 2.08658 13.6558 2.2268 13.5155L8.29475 7.44776C8.4353 7.307 8.51249 7.11905 8.51205 6.91925C8.51249 6.71867 8.4353 6.53084 8.29475 6.39018Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowPageRight;
