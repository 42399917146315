import { useMemo } from "react";
import { IReportViewSheet } from "../../../interfaces/reportView";
import { processChartContent } from "../helpers/chart";

const useChartData = (
  reportData: any[],
  photos: any[],
  groupBy: string | string[],
  groupByDate: string,
  reportSheet: IReportViewSheet
) => {
  const { tableHeader, tableSubHeader, tableContent, chartContent: chartData} = useMemo(
    () =>
      processChartContent(
        reportData,
        photos,
        groupBy,
        groupByDate,
        reportSheet
      ),
    [reportData, photos, groupBy, groupByDate, reportSheet]
  );

  return { chartData, tableHeader, tableSubHeader, tableContent };
};

export default useChartData;
