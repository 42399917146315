import { useEffect, useState } from "react";
import { DownloadOption } from "../../const/enums/DownloadOption";

import IconButton from "../Common/IconButton";
import IconToggle from "../Common/IconToggle";
import PopOver from "../Common/PopOver";

import { useAppSelector } from "../../redux/hooks";
import { getFileSizeEstimations } from "../../utils/download";

import DownloadIcon from "../icons/DownloadIcon";
import FitScreenIcon from "../icons/FitScreenIcon";
import FullScreenIcon from "../icons/FullScreenIcon";
import HelpIcon from "../icons/HelpIcon";
import HomeEmptyIcon from "../icons/HomeEmptyIcon";
import RestoreIcon from "../icons/RestoreIcon";
import ShareIcon from "../icons/ShareIcon";
import { FitOption } from "../../const/enums/FitOptions";
import { ViewType } from "../../const/enums/ViewType";
import ChartIcon from "../icons/ChartIcon";
import TableIcon from "../icons/TableIcon";
import { ReportSheetType } from "../../const/enums/ReportSheetType";
import MinusIcon from "../icons/MinusIcon";
import PlusIcon2 from "../icons/PlusIcon2";

interface ReportActionsProps {
  onFullScreen: () => void;
  onViewType: () => void;

  isFullScreen: boolean;
  fitOption: string;
  viewType: string;
  sheetType: ReportSheetType;
  
  handleFitOption: (selectedOption: string) => void;
  handleShareSC: () => void;
  handleDownload: (selectedOption: string) => void;
  increaseScale: () => void;
  decreaseScale: () => void;

  isHomeSheet: boolean;
  handleToggleHomeSheet: () => void;
}

const ReportActions = ({
  onFullScreen,
  isFullScreen,
  fitOption,
  onViewType,
  viewType,
  sheetType,
  handleFitOption,
  handleShareSC,
  handleDownload,
  isHomeSheet,
  increaseScale,
  decreaseScale,
  handleToggleHomeSheet,
}: ReportActionsProps) => {
  const { reportData, reportDataInfo } = useAppSelector(
    (state) => state.report
  );
  const [downloadPopOverShow, setDownloadPopOverShow] = useState(false);
  const [fitPopOverShow, setFitPopOverShow] = useState(false);
  const [helpPopOverShow, setHelpPopOverShow] = useState(false);

  const [downloadInfo, setDownloadInfo] = useState<any[]>([]);
  const [fitOptions, setFitOptions] = useState<any[]>([]);

  useEffect(() => {
    if (reportData.data.length === 0) return;

    const { estimatedSizeKB, estimatedXLSXSizeKB } = getFileSizeEstimations(
      reportData.data[0],
      reportDataInfo
    );

    setDownloadInfo([
      {
        itemName: DownloadOption.CSV,
        itemDescription: `${Math.floor(estimatedSizeKB)} KB`,
      },

      {
        itemName: DownloadOption.EXCEL,
        itemDescription: `${Math.floor(estimatedXLSXSizeKB)} KB`,
      },
    ]);

    setFitOptions(Object.values(FitOption).map(fitOpt => ({
      itemName: fitOpt,
      itemDescription: ''
    })));
  }, [reportData.data, reportDataInfo]);

  return (
    <div className="flex justify-end items-center gap-[8px] px-ml mt-sm z-20">
      
      <IconToggle
        value={isHomeSheet}
        className="!w-5 !h-5"
        onValueChange={handleToggleHomeSheet}
        iconOn={<HomeEmptyIcon className="w-5 h-5 text-primaryText" />}
        iconOff={<HomeEmptyIcon className="w-5 h-5 text-primaryText" />}
      ></IconToggle>
      
      {sheetType !== ReportSheetType.Table && <IconButton
        size={24}
        padding={0}
        className={"hover:bg-transparent flex"}
        onClick={onViewType}
      >
        {viewType === ViewType.CHART ? (
          <ChartIcon className="text-primaryText active:text-primary" />
        ) : (
          <TableIcon className="text-primaryText text-xl active:text-primary" />
        )}
      </IconButton>}

      <PopOver
        title="Legend"
        isOpen={helpPopOverShow}
        setIsOpen={setHelpPopOverShow}
        items={[
          { itemName: "EFP", itemDescription: "Effective Families Protected" },
          { itemName: "PRMR", itemDescription: "Recurring Monthly Revenue" },
        ]}
        itemAction={(selectedItem) => {}}
        className="bg-custom-dropdownBg w-[260px] top-[24px] left-[-110px]"
        itemNameColor="text-custom-dropdownText"
        itemDescriptionColor="text-custom-dropdownText opacity-50"
      >
        <IconButton
          size={20}
          padding={0}
          className={"hover:bg-transparent"}
          onClick={() => {
            setHelpPopOverShow((prev) => !prev);
          }}
        >
          <HelpIcon className="text-primaryText active:text-primary" />
        </IconButton>
      </PopOver>

      <PopOver
        title="File Type"
        isOpen={downloadPopOverShow}
        setIsOpen={setDownloadPopOverShow}
        items={downloadInfo}
        itemAction={handleDownload}
        className="bg-custom-dropdownBg w-[120px] top-[24px]"
        itemNameColor="text-custom-dropdownText active:text-primaryText"
        itemDescriptionColor="text-custom-dropdownText opacity-50 active:text-primaryText"
      >
        <IconButton
          size={20}
          padding={0}
          className={"hover:bg-transparent"}
          onClick={() => {
            setDownloadPopOverShow(true);
          }}
        >
          <DownloadIcon className="text-primaryText active:text-primary" />
        </IconButton>
      </PopOver>

      <PopOver
        title="Fit Options"
        isOpen={fitPopOverShow}
        setIsOpen={setFitPopOverShow}
        items={fitOptions}
        itemAction={handleFitOption}
        className="bg-custom-dropdownBg w-[120px] top-[24px]"
        itemNameColor="text-custom-dropdownText active:text-primaryText"
        itemDescriptionColor="text-custom-dropdownText opacity-50 active:text-primaryText"
        customActions={
          <div className="flex gap-2 text-black">
            <IconButton
              size={20}
              padding={0}
              className={"hover:bg-transparent"}
              onClick={decreaseScale}
            >
              <MinusIcon className="text-black font-bold active:text-primary" />
            </IconButton>
            
            <IconButton
              size={20}
              padding={0}
              className={"hover:bg-transparent"}
              onClick={increaseScale}
            >
              <PlusIcon2 className="text-black font-bold active:text-primary" />
            </IconButton>
          </div>
        }
      >
        <IconButton
          size={20}
          padding={0}
          className={"hover:bg-transparent"}
          onClick={() => {
            setFitPopOverShow(true);
          }}
        >
          <FitScreenIcon className="text-primaryText active:text-primary" />
        </IconButton>
      </PopOver>

      <IconButton
        size={20}
        padding={0}
        className={"hover:bg-transparent"}
        onClick={onFullScreen}
      >
        {isFullScreen ? (
          <RestoreIcon className="text-primaryText active:text-primary" />
        ) : (
          <FullScreenIcon className="text-primaryText active:text-primary" />
        )}
      </IconButton>

      <IconButton
        size={20}
        padding={0}
        className={"hover:bg-transparent"}
        onClick={handleShareSC}
      >
        <ShareIcon className="text-primaryText active:text-primary" />
      </IconButton>
    </div>
  );
};

export default ReportActions;
