import { handleError } from ".";
import axiosInstance from "./axiosInstance";

export const getMatchDataInfo = async (division: string, matchName: string) => {
  try {
    const response = await axiosInstance.get(
      `/viper/match_data?division=${division}&match_name=${matchName}`
    );

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const getBracketData = async (division: string) => {
  try {
    const response = await axiosInstance.get(
      `/viper/bracket_data?division=${division}`
    );

    return response.data;
  } catch (error) {
    handleError(error);
  }
};
