import { useEffect } from "react";
import toast from "react-hot-toast";

import TheCupBracket from "../Common/TheCupBracket";
import TheCupDivisionList from "../Common/TheCupDivisionList";
import IconButton from "../../../Common/IconButton";

import HomeEmptyIcon from "../../../icons/HomeEmptyIcon";

import { generateCupBracketRounds } from "../helper/bracket";

import { HomeComeptitionCardType } from "../../../../const/enums/HomeCompetitionCardType";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { getCupBracketListAction, getHomeCupBracketListAction } from "../../../../redux/slices/cupSlice";
import { saveHomeCompetitionCardsAction } from "../../../../redux/slices/reportSlice";

interface OtherMatchUpProps {
  isHome?: boolean,
  division?: string,
  card_name?: string,
}

const OtherMatchUp = ({
  isHome = false,
  division = "",
  card_name = ""
}: OtherMatchUpProps) => {
  const dispatch = useAppDispatch();
  const { currentDivision, bracketList, homeCards, isLoadingBracketList } = useAppSelector(
    (state) => state.cup
  );
  const firstRound = !isHome ? generateCupBracketRounds(bracketList) : (homeCards[card_name] ? generateCupBracketRounds(homeCards[card_name]) : []);

  useEffect(() => {
    if (division && isHome)
      dispatch(getHomeCupBracketListAction({
        card_name: card_name,
        division
      }));
    else
      dispatch(getCupBracketListAction(currentDivision));
  }, [currentDivision, card_name, division, isHome, dispatch]);

  const handleHomeCard = () => {
    toast.promise(
      dispatch(saveHomeCompetitionCardsAction({
        cardName: "The Cup - Other match ups - " + currentDivision,
        cardType: HomeComeptitionCardType.TheCupOtherMatchUps,
        cardParams: JSON.stringify({
          division: currentDivision
        })
      })).unwrap(),
      {
        loading: "Adding card to home screen",
        success: () => {
          return `Added card to home screen.`;
        },
        error: "The card is already added to home screen.",
      }
    );
  }

  return (
    <div className={`flex flex-col gap-4 px-5 ${!isHome ? "mt-[30px]" : "w-full"}`}>
      {!isHome &&
      <div className="flex justify-between">
        <div className="font-plus text-white text-lg not-italic font-semibold leading-[150%]">
        Other match ups
        </div>
        <IconButton
          size={24}
          padding={0}
          className={"hover:bg-transparent flex"}
          onClick={handleHomeCard}
        >
          <HomeEmptyIcon className="w-5 h-5 text-white" />
        </IconButton>
      </div>
      }

      {!isHome ? <TheCupDivisionList /> : ""}

      <div className="flex flex-col w-full">
        {isLoadingBracketList || firstRound.length === 0 ? (
          <div className="m-auto flex items-center justify-between mt-10">
            <div className="w-8 h-8 border-2 border-t-thecup-primary-main border-gray-300 rounded-full animate-spin"></div>
          </div>
        ) : (
          firstRound[0][0]?.seeds.map(
            (seed, index) => {
              return <TheCupBracket key={index} seed={seed} />;
            }
          )
        )}
      </div>
    </div>
  );
};
export default OtherMatchUp;
