const TheCupWinnerBadgeIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="21"
      viewBox="0 0 15 21"
      fill="none"
      className={className}
    >
      <path
        d="M10.8377 14.6229L11.7919 13.0746L13.5138 12.4874L13.7045 10.6789L15 9.40355L14.3841 7.6917L15 5.97981L13.7046 4.70446L13.5138 2.89603L11.7919 2.30879L10.8378 0.760482L9.03307 0.978385L7.5 0L5.96693 0.978466L4.16225 0.760563L3.20812 2.30883L1.48624 2.89607L1.29548 4.7045L0 5.97985L0.615933 7.69174L0 9.40363L1.29544 10.6789L1.4862 12.4874L3.20807 13.0747L4.16221 14.6229L5.96693 14.4051L7.5 15.3835L9.03307 14.4051L10.8377 14.6229ZM2.22545 7.69174C2.22545 4.78336 4.59162 2.41719 7.5 2.41719C10.4084 2.41719 12.7745 4.78336 12.7745 7.69174C12.7745 10.6001 10.4084 12.9663 7.5 12.9663C4.59162 12.9663 2.22545 10.6001 2.22545 7.69174Z"
        fill="currentColor"
      />
      <path
        d="M7.49999 3.64053C5.26615 3.64053 3.44879 5.45789 3.44879 7.69173C3.44879 9.92558 5.26615 11.7429 7.49999 11.7429C9.73384 11.7429 11.5512 9.92558 11.5512 7.69173C11.5512 5.45789 9.73384 3.64053 7.49999 3.64053Z"
        fill="currentColor"
      />
      <path
        d="M5.67679 15.6721L3.52996 15.9313L2.39569 14.0906L2.01009 13.9591L0.346863 19.1578L3.34115 18.9931L5.6838 20.8653L7.06238 16.5564L5.67679 15.6721Z"
        fill="currentColor"
      />
      <path
        d="M12.6028 14.0906L11.4685 15.9313L9.32168 15.6721L7.9361 16.5564L9.31467 20.8653L11.6573 18.9931L14.6516 19.1578L12.9884 13.9591L12.6028 14.0906Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TheCupWinnerBadgeIcon;
