import { PageType } from "./enums/PageType";

export const CompetitionIndex: Record<string, number> = {
  [PageType.CompetitionViper]: 0,
  [PageType.CompetitionTheCup]: 1,
};

export const CompetitionLink: Record<string, string> = {
  [PageType.CompetitionViper]: "viper",
  [PageType.CompetitionTheCup]: "thecup",
};
