import classNames from "classnames";
import {
  TheCupDivisionPumaList,
  TheCupDivisionRegularList,
} from "../../../../data/TheCup";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { updateCurrentDivision } from "../../../../redux/slices/cupSlice";

const TheCupDivisionList = () => {
  const dispatch = useAppDispatch();
  const { currentDivision } = useAppSelector((state) => state.cup);

  const updateSelectedDivision = (division: string) => {
    dispatch(updateCurrentDivision(division));
  };

  return (
    <div>
      <div className="flex gap-2 w-full px-1 pb-2 overflow-auto scrollbar-thin scrollbar-thumb-[#a1a6c0] scrollbar-track-[#1a1c2e] scrollbar-thumb-rounded-full scrollbar-track-rounded-full relative gradient-mask">
        {TheCupDivisionRegularList.map((division, index) => {
          return (
            <div
              className={classNames(
                "px-[12px] py-[6px] transition rounded-[8px] cursor-pointer font-semibold leading-[150%] text-xs not-italic whitespace-nowrap font-plus",
                {
                  "bg-thecup-secondary-main text-thecup-secondary-text":
                    currentDivision === division,
                  "bg-thecup-darkGrayishBlue text-white":
                    currentDivision !== division,
                }
              )}
              onClick={() => {
                updateSelectedDivision(division);
              }}
              key={index}
            >
              {division}
            </div>
          );
        })}

        {TheCupDivisionPumaList.map((division, index) => {
          return (
            <div
              className={classNames(
                "px-[12px] py-[6px] transition rounded-[8px] cursor-pointer font-semibold leading-[150%] text-xs not-italic whitespace-nowrap font-plus",
                {
                  "bg-thecup-secondary-main text-thecup-secondary-text":
                    currentDivision === division,
                  "bg-thecup-darkGrayishBlue text-white":
                    currentDivision !== division,
                }
              )}
              onClick={() => {
                updateSelectedDivision(division);
              }}
              key={index}
            >
              {division}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TheCupDivisionList;
