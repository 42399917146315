import { useEffect, useState } from "react";

import { useAppSelector } from "../../../redux/hooks";

import { Tab } from "../Viper";

import TheCupAccountDetail from "./Brackets/TheCupAccountDetails";
import TheCupRepDetails from "./Brackets/TheCupRepDetails";
import TheCupHeader from "./Common/TheCupHeader";
import TheCupPoster from "./Common/TheCupPoster";
import TheCupBracketsTab, { TheCupBracketsTabProps } from "./TheCupBracketsTab";
import TheCupOverview from "./TheCupOverviewTab";
import TheCupPrizes from "./TheCupPrizesTab";
import TheCupSeeding from "./TheCupSeedingTab";
import TheCupTabs from "./TheCupTabs";

// Define the Tab interface to accept components with props
const tabs: Tab<any>[] = [
  { name: "Overview", component: TheCupOverview },
  {
    name: "Brackets",
    component: TheCupBracketsTab as React.FC<TheCupBracketsTabProps>,
  },
  { name: "Seeding", component: TheCupSeeding },
  { name: "Prizes", component: TheCupPrizes },
];

const TheCup = () => {
  const [currentTab, setCurrentTab] = useState(tabs[0].name);
  const [posterVisible, setPosterVisible] = useState(true);
  const [showingRepDetails, setShowingRepDetails] = useState(false);
  const [showingAccountDetails, setShowingAccountDetails] = useState(false);

  const { currentDetailInfo } = useAppSelector((state) => state.cup);

  const CurrentTabComponent = tabs.find(
    (tab) => tab.name === currentTab
  )?.component;

  // Example props to pass to TheCupBracketsTab
  const cupBracketsProps: TheCupBracketsTabProps = {
    showingRepDetails,
    showingAccountDetails,
    showAccountDetails: (value: boolean) => setShowingAccountDetails(value),
    showRepDetails: (value: boolean) => setShowingRepDetails(value),
  };

  const title = showingRepDetails
    ? "Rep Details"
    : showingAccountDetails
    ? "Account Details"
    : "The Cup XVII";

  useEffect(() => {
    setPosterVisible(currentTab === "Overview");
  }, [currentTab]);

  return (
    <div className="w-full bg-thecup-midnightDepth max-h-fit-height min-h-fit-height overflow-y-auto pb-10">
      <div className="max-w-[500px] md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] mx-auto">
        {(showingRepDetails || showingAccountDetails) && (
          <TheCupHeader
            title={title}
            onBack={() => {
              setShowingRepDetails(false);
              setShowingAccountDetails(false);
            }}
          />
        )}

        <TheCupPoster isVisible={posterVisible} />

        {!showingRepDetails && !showingAccountDetails && (
          <TheCupTabs
            tabs={tabs}
            currentTab={currentTab}
            onChange={setCurrentTab}
          />
        )}

        {!showingRepDetails &&
          !showingAccountDetails &&
          CurrentTabComponent && (
            <CurrentTabComponent
              {...(CurrentTabComponent === TheCupBracketsTab
                ? cupBracketsProps
                : {})}
            />
          )}

        {showingRepDetails && (
          <TheCupRepDetails
            officeName={currentDetailInfo.officeName}
            division={currentDetailInfo.division}
            matchName={currentDetailInfo.matchName}
          />
        )}

        {showingAccountDetails && (
          <TheCupAccountDetail
            officeName={currentDetailInfo.officeName}
            division={currentDetailInfo.division}
            matchName={currentDetailInfo.matchName}
          />
        )}
      </div>
    </div>
  );
};

export default TheCup;
