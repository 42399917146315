import classNames from "classnames";
import { ViperMatchLists } from "../../../data/Viper";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { updateCurrentMatch } from "../../../redux/slices/viperSlice";

const ViperMatchListTab = () => {
  const dispatch = useAppDispatch();
  const { currentMatch } = useAppSelector((state) => state.viper);

  const handleCurrentMatchChange = (matchName: string) => {
    dispatch(updateCurrentMatch(matchName));
  };

  return (
    <div className="flex gap-3 px-6 w-full pt-[18px] pb-2 overflow-x-auto scrollbar-thin scrollbar-thumb-[#a1a6c0] scrollbar-track-[#1a1c2e] scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
      {ViperMatchLists.map((match, index) => {
        return (
          <div
            className={classNames(
              "px-[14px] py-[6.5px] transition rounded-[10px] cursor-pointer font-medium leading-[120%] text-base not-italic text-white font-primary whitespace-nowrap font-work",
              {
                "bg-viper-primary": currentMatch === match,
                "bg-viper-darkGrayishBlue": currentMatch !== match,
              }
            )}
            onClick={() => {
              handleCurrentMatchChange(match);
            }}
            key={index}
          >
            {match}
          </div>
        );
      })}
    </div>
  );
};

export default ViperMatchListTab;
