import { ICupAccountDetail } from "../../interfaces/cupAccountDetail";
import { ICupBracket } from "../../interfaces/cupBracket";
import { ICupRepDetail } from "../../interfaces/cupRepDetail";
import { ICupSeeding } from "../../interfaces/cupSeeding";

export const parseCupSeedingList = (data: { puma: any[]; regular: any[] }) => {
  return {
    puma: data.puma.map(
      (seeding) =>
        ({
          officeName: seeding.office_name,
          baselineRepScore: seeding.baseline_rep_score,
          fp: seeding.fp,
          efp: seeding.efp,
          divisionName: seeding.division_name,
          seeding: seeding.seeding,
          cupDivisionName: seeding.cup_division_name,
        } as ICupSeeding)
    ),
    regular: data.regular.map(
      (seeding) =>
        ({
          officeName: seeding.office_name,
          baselineRepScore: seeding.baseline_rep_score,
          fp: seeding.fp,
          efp: seeding.efp,
          divisionName: seeding.division_name,
          seeding: seeding.seeding,
          cupDivisionName: seeding.cup_division_name,
        } as ICupSeeding)
    ),
  };
};

export const parseCupBracketList = (brackets: any[]): ICupBracket[] => {
  return brackets.map(
    (data) =>
      ({
        id: data.id,
        competitionName: data.competition_name,
        competitionId: data.competition_id,
        divisionName: data.division_name,
        officeId: data.office_id,
        officeName: data.office_name,
        opponent: data.opponent,
        seeding: data.seeding,
        frozenBaselineRepScore: data.frozen_baseline_rep_score,
        matchName: data.match_name,
        bracketSide: data.bracket_side,
        bracket: data.bracket,
        baselineRepScore: data.baseline_rep_score,
        matchRepScore: data.match_rep_score,
        differenceRepScore: data.difference_rep_score,
        ytdCashPoints: data.ytd_cash_points,
        ytdRepScore: data.ytd_rep_score,
        rowNum: data.row_num,
        roundRank: data.round_rank,
        finalObject: data.final_object,
        dependencyName: data.dependency_name,
        matchId: data.match_id,
        matchStart: data.match_start,
        matchEnd: data.match_end,
        xPositionStart: data.x_position_start,
        yPositionStart: data.y_position_start,
        xPositionEnd: data.x_position_end,
        yPositionEnd: data.y_position_end,
      } as ICupBracket)
  );
};

export const parseCupRepDetails = (data: any[]) => {
  return data.map(
    (rep) =>
      ({
        employeeImage: rep.employee_image,
        rep: rep.rep,
        serviceNumber: parseInt(rep.service_number),
        in: rep.in,
        rs: rep.rs,
        trmr: rep.trmr,
        rmrPts: rep.rmr_pts,
        vppPts: rep.vpp_pts,
        pkgPts: rep.pkg_pts,
        lPts: rep.l_pts,
      } as ICupRepDetail)
  );
};

export const parseCupAccountDetails = (data: any[]) => {
  return data.map(
    (rep) =>
      ({
        service: rep.service,
        rep: rep.rep,
        ic: rep.ic,
        repScore: rep.rep_score,
        trmr: rep.trmr,
        trmrPts: rep.trmr_pts,
        vppPts: rep.vpp_pts,
        pkgPts: rep.pkg_pts,
        lPts: rep.l_pts,
      } as ICupAccountDetail)
  );
};

export const convertArrayCupAccountDetails = (data: ICupAccountDetail) => {
  return [
    data.rep,
    data.repScore,
    data.trmr,
    data.trmrPts,
    data.vppPts,
    data.pkgPts,
    data.service,
    data.lPts,
    data.ic,
  ];
};

export const convertCupRepDetails = (data: ICupRepDetail) => {
  return [
    data.rep,
    data.rs,
    data.trmr,
    data.rmrPts,
    data.vppPts,
    data.pkgPts,
    data.lPts,
  ];
};
