import Button from "../../components/Common/Button";

interface Props {
  currentTab: number;
  onSwitchTab: (tab: number) => void;
}

export default function ProfileTabs({ currentTab, onSwitchTab }: Props) {
  return (
    <div className="mt-8 mb-4 flex gap-4 px-3">
      {["Personal Stats", "5 Ahead 5 Behind"].map((title, tab) => (
        <Button
          key={title}
          className="rounded-md h-[36px]"
          bgColor={currentTab === tab ? "bg-primary" : "bg-[#383434]"}
          onClick={() => onSwitchTab(tab)}
        >
          {title}
        </Button>
      ))}
    </div>
  );
}
