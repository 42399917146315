import classNames from "classnames";
import Skeleton from "react-loading-skeleton";

import { ICupSeeding } from "../../../../interfaces/cupSeeding";
import HomeEmptyIcon from "../../../icons/HomeEmptyIcon";
import IconButton from "../../../Common/IconButton";

const tableFields = ["Office Name", "Baseline Rep Score", "FP", "EFP"];

const TheCupSeedingList = ({
  title,
  divisionList,
  isLoading,
  isHome = false,
  handleHomeCard = () => {},
  seedingList,
}: {
  title: string;
  divisionList: { name: string; colorClass: string }[];
  isLoading: boolean;
  isHome?: boolean;
  handleHomeCard?: () => void;
  seedingList: ICupSeeding[];
}) => {
  const skeletonCount = 13;

  return (
    <div className={`flex flex-col gap-4 px-5 ${!isHome ? "mt-[30px]" : "w-full"}`}>
      {!isHome &&
        <div className="flex justify-between">
          <div className="font-plus text-white text-lg not-italic font-semibold leading-[150%]">
            {title}
          </div>
          
        <IconButton
          size={24}
          padding={0}
          className={"hover:bg-transparent flex"}
          onClick={handleHomeCard}
        >
          <HomeEmptyIcon className="w-5 h-5 text-white" />
        </IconButton>
        </div>
      }

      <div className="flex gap-[15px] pb-2 overflow-auto scrollbar-thin scrollbar-thumb-[#a1a6c0] scrollbar-track-[#1a1c2e] scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
        {divisionList.map((seeding, index) => (
          <div key={index} className="flex gap-1 items-center justify-center">
            <div className={`w-2 h-2 rounded-full ${seeding.colorClass}`}></div>
            <div className="text-white font-plus text-[13px] not-italic font-medium leading-[150%] whitespace-nowrap">
              {seeding.name}
            </div>
          </div>
        ))}
      </div>

      <div className="w-full overflow-x-auto max-h-[600px] scrollbar-thin scrollbar-thumb-[#a1a6c0] scrollbar-track-[#1a1c2e] scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
        <div className="min-w-[640px] bg-[#484C5EA6] flex border-[1px] border-solid border-viper-mediumDarkGrayishBlue rounded-t-[6px]">
          <div className="w-10 border-r-[1px] border-solid border-viper-mediumDarkGrayishBlue"></div>

          {tableFields.map((field, index) => {
            return (
              <div
                className={classNames(`text-center whitespace-nowrap  `, {
                  "grow-[1]": index === 0,
                  "w-40": index === 1,
                  "!w-20": index > 1,
                  "border-r-[1px] border-solid border-viper-mediumDarkGrayishBlue":
                    index !== tableFields.length - 1,
                })}
                key={index}
              >
                <div className="py-[10px] font-plus text-[13px] not-italic font-semibold leading-[150%]">
                  {field}
                </div>
              </div>
            );
          })}
        </div>

        <div className="mt-[6px] pb-[14px]">
          {isLoading
            ? [...new Array(skeletonCount)].map((_, index) => {
                return (
                  <div
                    className={classNames(
                      "min-w-[640px] h-[41px] bg-[#2F324333] flex border-[1px] border-solid border-viper-mediumDarkGrayishBlue",
                      { "border-b-[0px]": index !== skeletonCount - 1 }
                    )}
                    key={index}
                  >
                    <div className="w-10 text-center border-r-[1px] border-solid border-viper-mediumDarkGrayishBlue whitespace-nowrap">
                      <div className="py-[10px] font-plus text-[13px] not-italic font-normal leading-[150%]">
                        {index + 1}
                      </div>
                    </div>
                    {tableFields.map((field, index) => {
                      return (
                        <div
                          className={classNames(
                            `text-center whitespace-nowrap  `,
                            {
                              "grow-[1]": index === 0,
                              "w-40": index === 1,
                              "!w-20": index > 1,
                              "border-r-[1px] border-solid border-viper-mediumDarkGrayishBlue":
                                index !== tableFields.length - 1,
                            }
                          )}
                          key={index}
                        >
                          <div className="py-[6px] h-[41px] font-plus text-[13px] not-italic font-normal">
                            <Skeleton
                              width={index === 0 ? 160 : 30}
                              height={21}
                              baseColor="#2F324366"
                              highlightColor="#2F3243"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })
            : seedingList.map((seeding, index) => {
                const textClassName =
                  "text-" +
                  divisionList
                    .find(
                      (division) =>
                        division.name.toLowerCase() ===
                        seeding.cupDivisionName.toLowerCase()
                    )
                    ?.colorClass.slice(3);

                return (
                  <div
                    className={classNames(
                      "min-w-[640px] bg-[#2F324333] flex border-[1px] border-solid border-viper-mediumDarkGrayishBlue",
                      "bg-[#2F324330] border-b-[1px]",
                      {
                        "!border-b-[0px]": index !== seedingList.length - 1,
                      }
                    )}
                    key={index}
                  >
                    <div className="w-10 text-center border-r-[1px] border-solid border-viper-mediumDarkGrayishBlue whitespace-nowrap">
                      <div className="py-[10px] font-plus text-[13px] not-italic font-normal leading-[150%]">
                        {seeding.seeding}
                      </div>
                    </div>
                    {tableFields.map((field, fieldIndex) => {
                      return (
                        <div
                          className={classNames(
                            `text-center whitespace-nowrap  `,
                            {
                              "grow-[1]": fieldIndex === 0,
                              "w-40": fieldIndex === 1,
                              "!w-20": fieldIndex > 1,
                              "border-r-[1px] border-solid border-viper-mediumDarkGrayishBlue":
                                fieldIndex !== tableFields.length - 1,
                            }
                          )}
                          key={fieldIndex}
                        >
                          <div
                            className={classNames(
                              `py-[10px] px-[10px] font-plus text-[13px] not-italic font-semibold leading-[150%] whitespace-nowrap overflow-hidden overflow-ellipsis ${
                                (fieldIndex > 0 && textClassName) ?? ""
                              }`
                            )}
                          >
                            {Object.values(seeding)[fieldIndex]}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default TheCupSeedingList;
