import { ReactNode, useState } from "react";
import ViperBattleIcon from "../../icons/ViperBattleIcon";

import ViperHeader from "./Common/ViperHeader";
import ViperBracketsTab from "./ViperBracketsTab";
import ViperOverviewTab from "./ViperOverviewTab";
import ViperPrizesTab from "./ViperPrizesTab";
import ViperSevenWeeksSeriesTab from "./ViperSevenWeeksSeriesTab";
import ViperTabs from "./ViperTabs";

export interface Tab<T = {}> {
  name: string;
  component: React.FC<T>; // The component to render for this tab
  icon?: ReactNode; // Optional icon component
}

const tabs: Tab[] = [
  { name: "Overview", component: ViperOverviewTab },
  {
    name: "Brackets",
    component: ViperBracketsTab,
    icon: <ViperBattleIcon className="absolute right-[-10px] top-0" />,
  },
  { name: "7 Week Series", component: ViperSevenWeeksSeriesTab },
  { name: "Prizes", component: ViperPrizesTab },
];

const Viper = () => {
  const [currentTab, setCurrentTab] = useState(tabs[0].name);

  const CurrentTabComponent = tabs.find(
    (tab) => tab.name === currentTab
  )?.component;

  return (
    <div className="w-full bg-viper-gradient max-h-fit-height min-h-fit-height overflow-y-auto pb-10 ">
      <div className="max-w-[500px] md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] mx-auto">
        <ViperHeader />

        <ViperTabs
          tabs={tabs}
          currentTab={currentTab}
          onChange={setCurrentTab}
        />

        {CurrentTabComponent && <CurrentTabComponent />}
      </div>
    </div>
  );
};

export default Viper;
