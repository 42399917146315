import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ICupAccountDetail } from "../../interfaces/cupAccountDetail";
import { ICupBracket } from "../../interfaces/cupBracket";
import { ICupRepDetail } from "../../interfaces/cupRepDetail";
import { ICupSeeding } from "../../interfaces/cupSeeding";
import {
  getCupBracketList,
  getCupSeedingList,
} from "../../services/cupService";
import {
  parseCupAccountDetails,
  parseCupBracketList,
  parseCupRepDetails,
  parseCupSeedingList,
} from "../helpers/cup";
import { TheCupDivisionRegularList } from "./../../data/TheCup";
import {
  getCupAccountDetails,
  getCupRepDetails,
} from "./../../services/cupService";

export interface CupState {
  isLoadingSeedingList: boolean;
  seedingList: {
    puma: ICupSeeding[];
    regular: ICupSeeding[];
  };

  isLoadingBracketList: boolean;
  bracketList: ICupBracket[];
  
  homeCards: {
    [key: string]: ICupBracket[];
  }

  isLoadingRepDetails: boolean;
  repDetails: ICupRepDetail[];

  isLoadingAccountDetails: boolean;
  accountDetails: ICupAccountDetail[];

  currentDivision: string;

  currentDetailInfo: {
    officeName: string;
    division: string;
    matchName: string;
  };
}

const initialState: CupState = {
  isLoadingSeedingList: false,
  seedingList: {
    puma: [],
    regular: [],
  },

  bracketList: [],
  isLoadingBracketList: false,

  repDetails: [],
  isLoadingRepDetails: false,

  accountDetails: [],
  homeCards: {},
  isLoadingAccountDetails: false,

  currentDivision: TheCupDivisionRegularList[4],

  currentDetailInfo: {
    officeName: "",
    division: "",
    matchName: "",
  },
};

export const getCupSeedingListAction = createAsyncThunk(
  "cup/getCupSeedingListAction",
  async () => {
    const response = await getCupSeedingList();
    return response;
  }
);

export const getCupBracketListAction = createAsyncThunk(
  "cup/getCupBracketListAction",
  async (division: string) => {
    const response = await getCupBracketList(division);
    return response;
  }
);


export const getHomeCupBracketListAction = createAsyncThunk(
  "cup/homeCards/getCupBracketListAction",
  async ({
    card_name,
    division
  }: {
    card_name: string,
    division: string
  }) => {
    const response = await getCupBracketList(division);
    return {
      card_name,
      response
    };
  }
);

export const getCupRepDetailsAction = createAsyncThunk(
  "cup/getCupRepDetailsAction",
  async (office: string) => {
    const response = await getCupRepDetails(office);
    return response;
  }
);

export const getCupAccountDetailsAction = createAsyncThunk(
  "cup/getCupAccountDetailsAction",
  async (office: string) => {
    const response = await getCupAccountDetails(office);
    return response;
  }
);

export const cupSlice = createSlice({
  name: "cup",
  initialState: initialState,
  reducers: {
    updateCurrentDivision: (state, { payload }) => {
      state.currentDivision = payload;
    },

    updateCurrentDetailInfo: (state, { payload }) => {
      state.currentDetailInfo = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCupSeedingListAction.pending, (state) => {
      state.isLoadingSeedingList = true;
    });
    builder.addCase(getCupSeedingListAction.fulfilled, (state, { payload }) => {
      state.seedingList = parseCupSeedingList(payload);
      state.isLoadingSeedingList = false;
    });

    builder.addCase(getCupSeedingListAction.rejected, (state, { error }) => {
      state.isLoadingSeedingList = false;
    });

    builder.addCase(getCupBracketListAction.pending, (state) => {
      state.isLoadingBracketList = true;
    });
    builder.addCase(getCupBracketListAction.fulfilled, (state, { payload }) => {
      state.bracketList = parseCupBracketList(payload);
      state.isLoadingBracketList = false;
    });
    builder.addCase(getCupBracketListAction.rejected, (state, { error }) => {
      state.isLoadingBracketList = false;
    });

    builder.addCase(getHomeCupBracketListAction.pending, (state) => {
      state.isLoadingBracketList = true;
    });
    builder.addCase(getHomeCupBracketListAction.fulfilled, (state, { payload }) => {
      state.homeCards[payload.card_name] = parseCupBracketList(payload.response);
      state.isLoadingBracketList = false;
    });
    builder.addCase(getHomeCupBracketListAction.rejected, (state, { error }) => {
      state.isLoadingBracketList = false;
    });


    builder.addCase(getCupRepDetailsAction.pending, (state) => {
      state.isLoadingRepDetails = true;
    });
    builder.addCase(getCupRepDetailsAction.fulfilled, (state, { payload }) => {
      state.repDetails = parseCupRepDetails(payload);
      state.isLoadingRepDetails = false;
    });
    builder.addCase(getCupRepDetailsAction.rejected, (state, { error }) => {
      state.isLoadingRepDetails = false;
    });

    builder.addCase(getCupAccountDetailsAction.pending, (state) => {
      state.isLoadingAccountDetails = true;
    });
    builder.addCase(
      getCupAccountDetailsAction.fulfilled,
      (state, { payload }) => {
        state.accountDetails = parseCupAccountDetails(payload);
        state.isLoadingAccountDetails = false;
      }
    );
    builder.addCase(getCupAccountDetailsAction.rejected, (state, { error }) => {
      state.isLoadingAccountDetails = true;
    });
  },
});

export const { updateCurrentDivision, updateCurrentDetailInfo } =
  cupSlice.actions;

export default cupSlice.reducer;
