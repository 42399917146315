import classNames from "classnames";
import { Tab } from ".";

interface ViperTabsProps {
  currentTab: string;
  tabs: Tab[];
  onChange: (tab: string) => void;
}

const ViperTabs = ({ currentTab, tabs, onChange }: ViperTabsProps) => {
  return (
    <div className="overflow-x-auto scrollbar-thin scrollbar-thumb-[#a1a6c0] scrollbar-track-[#1a1c2e] scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
      <div className="bg-[#2f3243] mt-4 flex gap-2 px-[15px] w-max min-w-full">
        {tabs.map((tab, index) => (
          <div
            className={classNames(
              "px-3 py-3 font-work text-sm not-italic font-medium leading-[150%] cursor-pointer text-viper-softGrayishBlue transition transition-300ms select-none relative",
              {
                "text-white": currentTab === tab.name,
              }
            )}
            onClick={() => {
              onChange(tab.name);
            }}
            key={index}
          >
            {tab.name}
            {currentTab === tab.name && (
              <div className="absolute transition transition-300ms left-0 bottom-0 bg-viper-primary w-full h-[2px] rounded-[2px]"></div>
            )}
            {tab.icon}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ViperTabs;
