const ViperUsersIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      className={className}
    >
      <path
        d="M22.5 19.25C22.5 19.4489 22.421 19.6397 22.2803 19.7803C22.1397 19.921 21.9489 20 21.75 20H12.75C12.5511 20 12.3603 19.921 12.2197 19.7803C12.079 19.6397 12 19.4489 12 19.25C12 18.0565 12.4741 16.9119 13.318 16.068C14.1619 15.2241 15.3065 14.75 16.5 14.75H18C19.1935 14.75 20.3381 15.2241 21.182 16.068C22.0259 16.9119 22.5 18.0565 22.5 19.25ZM17.25 8C16.6567 8 16.0766 8.17595 15.5833 8.50559C15.0899 8.83524 14.7054 9.30377 14.4784 9.85195C14.2513 10.4001 14.1919 11.0033 14.3076 11.5853C14.4234 12.1672 14.7091 12.7018 15.1287 13.1213C15.5482 13.5409 16.0828 13.8266 16.6647 13.9424C17.2467 14.0581 17.8499 13.9987 18.398 13.7716C18.9462 13.5446 19.4148 13.1601 19.7444 12.6667C20.0741 12.1734 20.25 11.5933 20.25 11C20.25 10.2044 19.9339 9.44129 19.3713 8.87868C18.8087 8.31607 18.0456 8 17.25 8ZM10.5 8C9.90666 8 9.32664 8.17595 8.83329 8.50559C8.33994 8.83524 7.95542 9.30377 7.72836 9.85195C7.5013 10.4001 7.44189 11.0033 7.55764 11.5853C7.6734 12.1672 7.95912 12.7018 8.37868 13.1213C8.79824 13.5409 9.33279 13.8266 9.91473 13.9424C10.4967 14.0581 11.0999 13.9987 11.6481 13.7716C12.1962 13.5446 12.6648 13.1601 12.9944 12.6667C13.3241 12.1734 13.5 11.5933 13.5 11C13.5 10.2044 13.1839 9.44129 12.6213 8.87868C12.0587 8.31607 11.2956 8 10.5 8ZM10.5 19.25C10.4989 18.4622 10.6541 17.682 10.9566 16.9546C11.2592 16.2273 11.703 15.5671 12.2625 15.0125C11.8047 14.8396 11.3194 14.7507 10.83 14.75H10.17C9.06466 14.752 8.00515 15.192 7.22355 15.9736C6.44196 16.7552 6.00198 17.8147 6 18.92V19.25C6 19.4489 6.07902 19.6397 6.21967 19.7803C6.36032 19.921 6.55109 20 6.75 20H10.635C10.5477 19.7595 10.502 19.5059 10.5 19.25Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ViperUsersIcon;
