const ViperTrophyIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      className={className}
    >
      <path
        d="M19.6482 9.02302C19.4082 8.76802 19.0677 8.621 18.7167 8.621H17.5992C17.6038 8.42 17.6067 8.21302 17.6067 8H10.3993C10.3993 8.21299 10.4023 8.42 10.4068 8.621H9.28177C8.93076 8.621 8.59027 8.76802 8.35027 9.02302C8.10726 9.27954 7.98128 9.63051 8.00226 9.98603C8.20326 13.337 9.93277 15.44 12.6747 15.752L12.1437 17.6C11.5122 17.6 11.0008 18.1115 11.0008 18.743V20H17.0067V18.743C17.0067 18.1115 16.4953 17.6 15.8638 17.6L15.3328 15.752C18.0688 15.437 19.7967 13.3355 19.9963 9.98603C20.0172 9.63204 19.8913 9.28103 19.6482 9.02302ZM9.20076 9.91402C9.19927 9.88103 9.21277 9.85853 9.22475 9.84652C9.24725 9.82252 9.27427 9.82252 9.28177 9.82252H10.4638C10.6423 12.182 11.1628 13.52 11.6683 14.276C9.74378 13.469 9.28475 11.3135 9.20076 9.91402ZM16.3378 14.2715C16.8432 13.514 17.3623 12.1775 17.5407 9.82103H18.7182C18.7272 9.82103 18.7527 9.82103 18.7752 9.84502C18.7873 9.85704 18.8007 9.87954 18.7992 9.91252C18.7138 11.312 18.2563 13.4615 16.3378 14.2715Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ViperTrophyIcon;
