import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ViperDivisionList, ViperMatchLists } from "../../data/Viper";
import { IViperBracketData } from "../../interfaces/viperBracketData";
import { IViperMatchData } from "../../interfaces/viperMatchData";

import { getBracketData, getMatchDataInfo } from "../../services/viperService";
import { parseViperBracketData, parseViperMatchData } from "../helpers/viper";

export interface ViperState {
  viperMatchData: IViperMatchData[];
  isLoadingViperMatchData: boolean;

  viperBracketData: IViperBracketData[];
  isLoadingViperBracketData: boolean;

  currentDivision: string;
  currentMatch: string;
}

const initialState: ViperState = {
  viperMatchData: [],
  isLoadingViperMatchData: false,

  viperBracketData: [],
  isLoadingViperBracketData: false,

  currentDivision: ViperDivisionList[1],
  currentMatch: ViperMatchLists[0],
};

export const getMatchDataInfoAction = createAsyncThunk(
  "viper/getMatchDataInfoAction",
  async ({ division, matchName }: { division: string; matchName: string }) => {
    const response = await getMatchDataInfo(division, matchName);

    return response;
  }
);

export const getBracketDataAction = createAsyncThunk(
  "viper/getBracketDataAction",
  async ({ division }: { division: string }) => {
    const response = await getBracketData(division);

    return response;
  }
);

const viperSlice = createSlice({
  name: "viper",
  initialState: initialState,
  reducers: {
    updateCurrentMatch: (state, { payload }) => {
      state.currentMatch = payload;
    },

    updateCurrentDivision: (state, { payload }) => {
      state.currentDivision = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMatchDataInfoAction.pending, (state) => {
      state.isLoadingViperMatchData = true;
    });
    builder.addCase(getMatchDataInfoAction.fulfilled, (state, { payload }) => {
      state.viperMatchData = parseViperMatchData(payload);
      state.isLoadingViperMatchData = false;
    });
    builder.addCase(getMatchDataInfoAction.rejected, (state, { error }) => {
      state.isLoadingViperMatchData = false;
    });

    builder.addCase(getBracketDataAction.pending, (state) => {});
    builder.addCase(getBracketDataAction.fulfilled, (state, { payload }) => {
      state.viperBracketData = parseViperBracketData(payload);
    });
    builder.addCase(getBracketDataAction.rejected, (state, { error }) => {});
  },
});

export const { updateCurrentMatch, updateCurrentDivision } = viperSlice.actions;

export default viperSlice.reducer;
