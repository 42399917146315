import { IRoundProps, ISeedProps } from "react-brackets";
import { ICupBracket } from "../../../../interfaces/cupBracket";
import { IViperBracketData } from "../../../../interfaces/viperBracketData";
import { formatDateString } from "../../../../utils/date";

export const generateCupBracketRounds = (brackets: ICupBracket[]) => {
  const winnerRounds: IRoundProps[] = [];

  const loserRounds: IRoundProps[] = [];

  const matchNames: string[] = [];

  const matchKeys: string[] = [];

  brackets.forEach((bracket) => {
    const matchName = bracket.matchName;
    if (matchNames.indexOf(matchName) === -1) {
      matchNames.push(matchName);

      const matchBrackets = brackets.filter(
        (bracket) => bracket.matchName === matchName
      );

      const seeds: ISeedProps[] = [];

      matchBrackets.forEach((bracket) => {
        const nameFlag = bracket.officeName > bracket.opponent;

        const matchKey = `${matchName}-${
          nameFlag ? bracket.officeName : bracket.opponent
        }-${nameFlag ? bracket.opponent : bracket.officeName}`;

        if (matchKeys.indexOf(matchKey) === -1) {
          matchKeys.push(matchKey);

          const currentBracket: ICupBracket = matchBrackets.filter((b1) => {
            return b1.officeName === bracket.officeName;
          })[0];

          const opponentBracket: ICupBracket = matchBrackets.filter((b1) => {
            return b1.officeName === bracket.opponent;
          })[0];

          if (!currentBracket || !opponentBracket) return;

          const order =
            currentBracket.yPositionStart !== opponentBracket.yPositionStart
              ? currentBracket.yPositionStart < opponentBracket.yPositionStart
              : currentBracket.xPositionStart < opponentBracket.xPositionStart;

          const {
            officeName: currentOffice,
            differenceRepScore: currentScore,
            baselineRepScore: baselineScore,
            matchRepScore: matchScore,
          } = currentBracket;

          const {
            officeName: opponentOffice,
            differenceRepScore: opponentScore,
            baselineRepScore: opponentBaselineScore,
            matchRepScore: opponentMatchScore,
          } = opponentBracket;

          const teams = [
            {
              id: order ? currentOffice : opponentOffice,
              name: order ? currentOffice : opponentOffice,
              score: order ? currentScore : opponentScore,
              baselineScore: order ? baselineScore : opponentBaselineScore,
              matchScore: order ? matchScore : opponentMatchScore,
              isWinner: order
                ? currentScore > opponentScore
                : currentScore < opponentScore,
            },
            {
              id: order ? opponentOffice : currentOffice,
              name: order ? opponentOffice : currentOffice,
              score: order ? opponentScore : currentScore,
              baselineScore: order ? opponentBaselineScore : baselineScore,
              matchScore: order ? opponentMatchScore : matchScore,
              isWinner: order
                ? currentScore < opponentScore
                : currentScore > opponentScore,
            },
          ];

          const seed: ISeedProps = {
            id: matchKey,
            bracketId: currentBracket.id,
            teams: teams,
          };

          seeds.push(seed);
        }
      });

      const round: IRoundProps = {
        title:
          bracket.matchName !== "Championship"
            ? bracket.competitionName + " - " + bracket.matchName
            : bracket.matchName,
        data: `${formatDateString(
          bracket.matchStart || ""
        )} ~ ${formatDateString(bracket.matchEnd || "")}`,
        seeds: seeds,
      };

      if (
        bracket.bracket === "WINNER" ||
        bracket.bracket === "SINGLE MATCHES"
      ) {
        winnerRounds.push(round);
      } else {
        loserRounds.push(round);
      }
    }
  });

  return [winnerRounds, loserRounds];
};

export const generateMatchDetail = (bracket: IViperBracketData) => {
  return {
    WINS: [bracket.wins, bracket.losses],
    FP: [bracket.fp, bracket.opponentFp],
    CAM: [bracket.cam, bracket.opponentCam],
    UPEFP: [bracket.upefp, bracket.opponentUpefp],
    CASH: [bracket.cash, bracket.opponentCash],
    EXC: [bracket.exc, bracket.opponentExc],
    LIGHT: [bracket.light, bracket.opponentLight],
    WRTY: [bracket.wrty, bracket.opponentWrty],
    AVGRMR: [bracket.avgrmr, bracket.opponentAvgrmr],
  };
};
