import { IRoundProps } from "react-brackets";
import { ViperBracketMatchDetail } from "../../../../data/Viper";
import { IViperBracketData } from "../../../../interfaces/viperBracketData";
import { formatDateString } from "../../../../utils/date";

function removeDuplicates(brackets: IViperBracketData[]) {
  const uniqueSet = new Set();
  return brackets.filter((bracket) => {
    // Create a sorted key based on the object's field values
    const key = JSON.stringify(
      Object.values({
        teamName: bracket.teamName,
        opponentTeamName: bracket.opponentTeamName,
      }).sort()
    );
    if (uniqueSet.has(key)) {
      return false; // Duplicate found
    } else {
      uniqueSet.add(key); // Add unique key to the set
      return true; // Keep this item
    }
  });
}

export const generateBracketRounds = (brackets: IViperBracketData[]) => {
  const seedIds: { [key: string]: Number } = {};

  const rounds = ViperBracketMatchDetail.map((detail) => {
    const filteredBrackets = removeDuplicates(
      brackets.filter(
        (bracket) =>
          bracket.matchId === detail.matchId &&
          bracket.matchName === detail.title
      )
    );

    console.log(filteredBrackets);

    const seeds = filteredBrackets
      .sort((bracket1, bracket2) =>
        bracket1.xPositionEnd - bracket2.xPositionEnd === 0
          ? bracket1.yPositionEnd - bracket2.yPositionEnd
          : bracket1.xPositionEnd - bracket2.xPositionEnd
      )
      .map((bracket, index) => {
        let isWinner;
        if (bracket.wins > bracket.losses) {
          isWinner = true;
        } else if (bracket.wins < bracket.losses) {
          isWinner = false;
        } else {
          if (
            bracket.tieBreakMatchRepScore >
            bracket.opponentTieBreakMatchRepScore
          ) {
            isWinner = true;
          } else if (
            bracket.tieBreakMatchRepScore <
            bracket.opponentTieBreakMatchRepScore
          ) {
            isWinner = false;
          } else {
            if (
              bracket.tieBreakYtdCashPoints >
              bracket.opponentTieBreakYtdCashPoints
            ) {
              isWinner = true;
            } else if (
              bracket.tieBreakYtdCashPoints <
              bracket.opponentTieBreakYtdCashPoints
            ) {
              isWinner = false;
            } else {
              if (
                bracket.tieBreakYtdRepScore >
                bracket.opponentTieBreakYtdRepScore
              ) {
                isWinner = true;
              } else if (
                bracket.tieBreakYtdRepScore <
                bracket.opponentTieBreakYtdRepScore
              ) {
                isWinner = false;
              } else {
                isWinner = true;
              }
            }
          }
        }

        if (!seedIds[bracket.teamName]) seedIds[bracket.teamName] = index + 1;
        if (!seedIds[bracket.opponentTeamName])
          seedIds[bracket.opponentTeamName] =
            filteredBrackets.length * 2 - index;

        const homeTeam = {
          id: seedIds[bracket.teamName],
          name: bracket.teamName,
          score: bracket.wins,
          isWinner: isWinner,
        };

        const awayTeam = {
          id: seedIds[bracket.opponentTeamName],
          name: bracket.opponentTeamName,
          score: bracket.losses,
          isWinner: !isWinner,
        };

        return {
          id: index + 1,
          bracketId: bracket.id,
          teams:
            homeTeam.id > awayTeam.id
              ? [awayTeam, homeTeam]
              : [homeTeam, awayTeam],
        };
      });

    return {
      title: detail.name,
      data: `${formatDateString(detail.start || "")} ~ ${formatDateString(
        detail.end || ""
      )}`,
      seeds: seeds,
    } as IRoundProps;
  });

  return rounds;
};

export const generateMatchDetail = (bracket: IViperBracketData) => {
  return {
    WINS: [bracket.wins, bracket.losses],
    FP: [bracket.fp, bracket.opponentFp],
    CAM: [bracket.cam, bracket.opponentCam],
    UPEFP: [bracket.upefp, bracket.opponentUpefp],
    CASH: [bracket.cash, bracket.opponentCash],
    EXC: [bracket.exc, bracket.opponentExc],
    LIGHT: [bracket.light, bracket.opponentLight],
    WRTY: [bracket.wrty, bracket.opponentWrty],
    AVGRMR: [bracket.avgrmr, bracket.opponentAvgrmr],
  };
};
