import { IViperMatchData } from "../../interfaces/viperMatchData";

export const parseViperMatchData = (payload: any[]) => {
  return payload.map(
    (data) =>
      ({
        awayTeamName: data.away_team_name,
        homeTeamName: data.home_team_name,
        wins: data.wins,
        losses: data.losses,
        ties: data.ties,
        fp: data.fp,
        cam: data.cam,
        upefp: data.upefp,
        cash: data.cash,
        exc: data.exc,
        light: data.light,
        wrty: data.wrty,
        avgrmr: data.avgrmr,
      } as IViperMatchData)
  );
};

export const parseViperBracketData = (payload: any[]) => {
  return payload.map((originalData) => ({
    id: originalData.id,
    divisionName: originalData.division_name,
    teamName: originalData.team_name,
    officeId: originalData.office_id,
    ranking: originalData.ranking,
    matchName: originalData.match_name,
    matchId: originalData.match_id,
    matchStart: originalData.match_start,
    matchEnd: originalData.match_end,
    yPositionStart: originalData.y_position_start,
    yPositionEnd: originalData.y_position_end,
    xPositionStart: originalData.x_position_start,
    xPositionEnd: originalData.x_position_end,
    bracketSide: originalData.bracket_side,
    fp: originalData.fp,
    cam: originalData.cam,
    upefp: originalData.upefp,
    cash: originalData.cash,
    exc: originalData.exc,
    light: originalData.light,
    wrty: originalData.wrty,
    avgrmr: originalData.avgrmr,
    opponentTeamName: originalData.opponent_team_name,
    opponentOfficeId: originalData.opponent_office_id,
    opponentRanking: originalData.opponent_ranking,
    opponentFp: originalData.opponent_fp,
    opponentCam: originalData.opponent_cam,
    opponentUpefp: originalData.opponent_upefp,
    opponentCash: originalData.opponent_cash,
    opponentExc: originalData.opponent_exc,
    opponentLight: originalData.opponent_light,
    opponentWrty: originalData.opponent_wrty,
    opponentAvgrmr: originalData.opponent_avgrmr,
    tieBreakMatchRepScore: originalData.tie_break_match_rep_score,
    tieBreakYtdCashPoints: originalData.tie_break_ytd_cash_points,
    tieBreakYtdRepScore: originalData.tie_break_ytd_rep_score,
    opponentTieBreakMatchRepScore:
      originalData.opponent_tie_break_match_rep_score,
    opponentTieBreakYtdCashPoints:
      originalData.opponent_tie_break_ytd_cash_points,
    opponentTieBreakYtdRepScore: originalData.opponent_tie_break_ytd_rep_score,
    rowNum: originalData.row_num,
    wins: originalData.wins,
    losses: originalData.losses,
    ties: originalData.ties,
    fpColor: originalData.fp_color,
    camColor: originalData.cam_color,
    upefpColor: originalData.upefp_color,
    cashColor: originalData.cash_color,
    excColor: originalData.exc_color,
    lightColor: originalData.light_color,
    wrtyColor: originalData.wrty_color,
    avgrmrColor: originalData.avgrmr_color,
    winsColor: originalData.wins_color,
    roundRank: originalData.round_rank,
    finalObject: originalData.final_object,
    dependencyName: originalData.dependency_name,
    frozenTimestamp: originalData.frozen_timestamp,
  }));
};
