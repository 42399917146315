import classNames from "classnames";
import { Tab } from "../Viper";

interface TheCupTabsProps {
  currentTab: string;
  tabs: Tab[];
  onChange: (tab: string) => void;
}

const TheCupTabs = ({ currentTab, tabs, onChange }: TheCupTabsProps) => {
  return (
    <div className="overflow-x-auto scrollbar-thin scrollbar-thumb-[#a1a6c0] scrollbar-track-[#1a1c2e] scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
      <div className="mt-4 flex gap-2 px-[15px] pb-[16px] w-max min-w-full">
        {tabs.map((tab, index) => (
          <div
            className={classNames(
              "text-[#090D19] h-[35px] px-[15px] py-[6px] rounded-[8px] font-plus text-sm not-italic font-semibold leading-[150%] cursor-pointer transition transition-300ms select-none relative",
              {
                "bg-white shadow-thecup": currentTab === tab.name,
                "bg-thecup-secondary-main text-thecup-secondary-text":
                  currentTab !== tab.name,
              }
            )}
            onClick={() => {
              onChange(tab.name);
            }}
            key={index}
          >
            {tab.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TheCupTabs;
