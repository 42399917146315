import { handleError } from ".";
import axiosInstance from "./axiosInstance";

export const getCupSeedingList = async () => {
  try {
    const response = await axiosInstance.get("/cup/cup_seeding");

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const getCupBracketList = async (division: string) => {
  try {
    const response = await axiosInstance.get(
      `/cup/cup_bracket?division_name=${division}`
    );

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const getCupRepDetails = async (office: string) => {
  try {
    const response = await axiosInstance.get(
      `/cup/cup_rep_details?office_name=${office}`
    );

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const getCupAccountDetails = async (office: string) => {
  try {
    const response = await axiosInstance.get(
      `/cup/cup_account_details?office_name=${office}`
    );

    return response.data;
  } catch (error) {
    handleError(error);
  }
};
