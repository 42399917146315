import ViperIcon from "../../../icons/ViperIcon";
import ViperTrophyIcon from "../../../icons/ViperTrophyIcon";
import ViperUsersIcon from "../../../icons/ViperUsersIcon";

const ViperHeader = () => {
  return (
    <div className="w-full h-[225px] relative">
      <div
        className="w-full h-[225px] left-0 top-0 absolute bg-top object-contain"
        style={{
          background: "url('/assets/imgs/Viper/viper_bg.png') no-repeat",
          backgroundSize: "100% auto",
        }}
      />
      <div
        className="w-full h-[225px] left-0 top-0 absolute bg-center"
        style={{
          background:
            "url('/assets/imgs/Viper/viper_bg_gradient.png') 100% 100%",
        }}
      />

      <div className="w-full h-full absolute pl-[28px] pr-[20px] pt-[17px] pb-[14px]">
        <ViperIcon className="w-[58px] h-[58px] text-viper-primary" />

        <div className="flex gap-2 items-center mt-5">
          <div className="bg-viper-primary w-[2px] h-[18px] rounded-[2px]"></div>

          <div className="text-viper-primary font-work text-base not-italic font-medium leading-[150%]">
            Purple Division
          </div>
        </div>

        <div className="mt-2 font-work text-[28px]">B Owens Team</div>

        <div className="flex items-center">
          <ViperTrophyIcon className="text-viper-softGrayishBlue" />
          <h4 className="text-viper-softGrayishBlue font-work text-[15px] not-italic font-medium leading-[150%]">
            Currently Participating:
          </h4>
          <h4 className="text-white ml-1 font-work text-[15px] not-italic font-medium leading-[150%]">
            Final
          </h4>
        </div>

        <div className="flex items-center">
          <ViperUsersIcon className="text-viper-softGrayishBlue" />
          <h4 className="text-viper-softGrayishBlue font-work text-[15px] not-italic font-medium leading-[150%]">
            16/16 Team Members
          </h4>
        </div>
      </div>
    </div>
  );
};

export default ViperHeader;
