const prizes = [
  "https://www.figma.com/file/OZyH8ET9kjAggN5yQkMmek/image/fc9d83d37f6bc51592fa50cd5c871c02d9b43903?fuid=1215720084589520081",
  "https://www.figma.com/file/OZyH8ET9kjAggN5yQkMmek/image/56e1dc6857e4e85f3ea5ebb7a77e517986889cdb?fuid=1215720084589520081",
  "https://www.figma.com/file/OZyH8ET9kjAggN5yQkMmek/image/af9d9a0af69e5f96d03e01c528b0f3ae0b9081db?fuid=1215720084589520081",
  "https://www.figma.com/file/OZyH8ET9kjAggN5yQkMmek/image/0a599468aa326317bddce9a3b4b7471b425648c4?fuid=1215720084589520081",
  "https://www.figma.com/file/OZyH8ET9kjAggN5yQkMmek/image/6ce0776a093f2a188a1cc17efa0713919e97bbf5?fuid=1215720084589520081",
  "https://www.figma.com/file/OZyH8ET9kjAggN5yQkMmek/image/c1a3ef9098150178b5da212331a3b116af16dbe4?fuid=1215720084589520081",
  "https://www.figma.com/file/OZyH8ET9kjAggN5yQkMmek/image/518b19c1ea386ec3aab27fba98796f6c6544aac9?fuid=1215720084589520081",
  "https://www.figma.com/file/OZyH8ET9kjAggN5yQkMmek/image/1700250e9bc1ef0a20fbb92a2e63bff30756f51f?fuid=1215720084589520081",
  "https://www.figma.com/file/OZyH8ET9kjAggN5yQkMmek/image/e9b9d3b9230fc073a916affc77242a9447bdf33c?fuid=1215720084589520081",
];

interface ViperPrizesProps {
  prizeCount?: number;
}

const ViperPrizes = ({ prizeCount = 9 }: ViperPrizesProps) => {
  return (
    <div className="flex flex-col gap-[14px] px-6 mt-8">
      <div className="flex gap-2 items-center">
        <div className="bg-viper-primary w-[2px] h-[18px] rounded-[2px]"></div>

        <div className="flex items-end gap-2">
          <div className="text-white font-work text-base not-italic font-medium leading-[150%]">
            Prizes
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-[2px] md:grid-cols-4 lg:grid-cols-5 rounded-[3px] bg-viper-mediumDarkGrayishBlue border-[1px] border-solid border-viper-mediumDarkGrayishBlue">
        {prizes.slice(0, prizeCount).map((prize, index) => {
          return (
            <div className="grid-item rounded-[3px]" key={index}>
              <img
                src={prize}
                alt="Prize"
                className="w-full h-full object-cover"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ViperPrizes;
