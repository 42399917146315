import { XMarkIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import IconButton from "../../components/Common/IconButton";
import MobilePopup, {
  MobilePopupPosition,
} from "../../components/Common/MobilePopup";
import SearchIcon from "../../components/icons/SearchIcon";
import Button from "../../components/Common/Button";
import { ProfileCategory } from "../../interfaces/profile";
import useDebounce from "../../hooks/useDebounce";
import axiosInstance from "../../services/axiosInstance";

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function SearchModal({ open, onClose }: Props) {
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState(ProfileCategory.Rep);
  const [result, setResult] = useState<{ name: string; photo?: string }[]>([]);

  const searchProfiles = async () => {
    if (search?.length < 3) {
      setResult([]);
      return;
    }
    const { data } = await axiosInstance.get("/profile/search", {
      params: {
        category,
        search,
      },
    });
    setResult(data);
  };

  const searchKey = useDebounce(search, 800);

  useEffect(() => {
    searchProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);

  return (
    <MobilePopup
      isOpen={open}
      onClose={onClose}
      backBg="bg-overlay"
      popupBg="bg-[#0B0A0A]"
      popupBorder="ring-[#4E4949]"
      position={MobilePopupPosition.Bottom}
    >
      <div className="pt-4 pb-6 px-4 flex justify-between items-center">
        <h2 className="text-primaryText font-primary text-[18px] not-italic font-semibold leading-[120%]">
          Find Profile
        </h2>
        <IconButton onClick={onClose} padding={0}>
          <XMarkIcon className="size-6 text-primaryText" />
        </IconButton>
      </div>

      <div className="min-h-[400px]">
        <div className="w-full px-[12.5px]">
          <div className="relative w-full rounded-[8px] shadow-[0_0_0_0.4px_white] bg-custom-deepCharcoal10">
            <input
              placeholder="Search"
              type="text"
              className="bg-transparent rounded-[8px] h-[48px] px-[8px] p-[10px] text-white text-sm not-italic leading-[120%] transition-all outline-none focus:shadow-[0_0_8px_0_#FFC60C99] w-full"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />

            <IconButton
              onClick={() => {}}
              size={16}
              padding={0}
              className="hover:bg-transparent absolute top-3 right-2"
            >
              <SearchIcon className={"text-white"} />
            </IconButton>
          </div>
        </div>

        {search.length >= 3 ? (
          <div className="p-4">
            <div className="text-neutral-100 text-sm">Results:</div>
            <div className="mt-4 flex flex-col gap-3 max-h-[400px] overflow-y-auto">
              {result.length > 0 ? (
                result.map((item, index) => (
                  <Link
                    key={index}
                    className="flex gap-2 items-center"
                    to={`/profile?name=${item.name}&category=${category}`}
                    onClick={onClose}
                  >
                    {item.photo ? (
                      <img
                        src={item.photo}
                        className="size-6 rounded-full border border-primary"
                        alt=""
                      />
                    ) : (
                      <UserGroupIcon className="size-6" />
                    )}
                    <div className="text-sm font-light tracking-wide">
                      {item.name}
                    </div>
                  </Link>
                ))
              ) : (
                <div className="text-xs text-neutral-400">No results</div>
              )}
            </div>
          </div>
        ) : (
          <div className="pb-24 px-3">
            <div className="text-sm text-neutral-100 py-3">
              Or choose category:
            </div>
            <div className="flex flex-col gap-3">
              {Object.values(ProfileCategory).map((c) => (
                <Button
                  key={c}
                  bgColor={category === c ? "bg-neutral-400" : "bg-white"}
                  textColor={category === c ? "text-neutral-900" : "text-black"}
                  className="text-sm font-medium w-full h-[36px]"
                  onClick={() => setCategory(c)}
                >
                  {c}
                </Button>
              ))}
            </div>
          </div>
        )}
      </div>
    </MobilePopup>
  );
}
