import { useEffect } from "react";
import toast from "react-hot-toast";

import { HomeComeptitionCardType } from "../../../const/enums/HomeCompetitionCardType";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getCupSeedingListAction } from "../../../redux/slices/cupSlice";
import { saveHomeCompetitionCardsAction } from "../../../redux/slices/reportSlice";

import TheCupSeedingList from "./Common/TheCupSeedingList";

const regularDivisionList = [
  { name: "Onyx", colorClass: "bg-thecup-onyx", textClass: "text-thecup-onyx" },
  { name: "Jade", colorClass: "bg-thecup-jade", textClass: "text-thecup-jade" },
  {
    name: "Emerald",
    colorClass: "bg-thecup-emerald",
    textClass: "text-thecup-emerald",
  },
  { name: "Ruby", colorClass: "bg-thecup-ruby", textClass: "text-thecup-ruby" },
  {
    name: "Diamond",
    colorClass: "bg-thecup-diamond",
    textClass: "text-thecup-diamond",
  },
  {
    name: "No division",
    colorClass: "bg-thecup-no-division",
    textClass: "text-thecup-no-division",
  },
];

const pumaDivisionList = [
  { name: "Puma", colorClass: "bg-thecup-puma", textClass: "text-thecup-puma" },
  {
    name: "Puma Scout",
    colorClass: "bg-thecup-puma-scout",
    textClass: "text-thecup-puma-scout",
  },
];

interface TheCupSeedingProps {
  isHome?: boolean;
  type?: "regular" | "puma";
}

const TheCupSeeding = ({
  isHome = false,
  type = "regular"
}: TheCupSeedingProps) => {
  const dispatch = useAppDispatch();
  const { isLoadingSeedingList, seedingList } = useAppSelector(
    (state) => state.cup
  );

  useEffect(() => {
    dispatch(getCupSeedingListAction());
  }, [dispatch]);

  const handleHomeCard = (seedingType: "regular" | "puma") => {
    toast.promise(
      dispatch(saveHomeCompetitionCardsAction({
        cardName: "The Cup - Seeding" + (seedingType === "puma" ? " - Puma" : ""),
        cardType: HomeComeptitionCardType.TheCupSeeding,
        cardParams: JSON.stringify({
          type: seedingType
        })
      })).unwrap(),
      {
        loading: "Adding card to home screen",
        success: () => {
          return `Added card to home screen.`;
        },
        error: "The card is already added to home screen.",
      }
    );
  }

  return (
    <div className="w-full">
      {((type === "regular" && isHome) || !isHome) &&
        <TheCupSeedingList
          title="Seeding"
          divisionList={regularDivisionList}
          isLoading={isLoadingSeedingList}
          isHome={isHome}
          handleHomeCard={() => handleHomeCard("regular")}
          seedingList={seedingList.regular}
        />
      }

      {((type === "puma" && isHome) || !isHome) &&
        <TheCupSeedingList
          title="Seeding Puma"
          divisionList={pumaDivisionList}
          isLoading={isLoadingSeedingList}
          isHome={isHome}
          handleHomeCard={() => handleHomeCard("puma")}
          seedingList={seedingList.puma}
        />
      }
    </div>
  );
};

export default TheCupSeeding;
