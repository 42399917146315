import ChampionShipFinal from "./Overview/ChampionShipFinal";
import ViperMyPerformance from "./Common/ViperMyPerformance";
import ViperPrizes from "./Common/ViperPrizes";

const ViperOverviewTab = () => {
  return (
    <>
      <ChampionShipFinal />

      <ViperPrizes />

      <ViperMyPerformance />
    </>
  );
};

export default ViperOverviewTab;
