import classNames from "classnames";
import { useMemo } from "react";
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton";

import { HomeComeptitionCardType } from "../../../../const/enums/HomeCompetitionCardType";
import { ViperMatchDataFieldTypes } from "../../../../data/Viper";
import { IViperMatchData } from "../../../../interfaces/viperMatchData";

import IconButton from "../../../Common/IconButton";
import HomeEmptyIcon from "../../../icons/HomeEmptyIcon";

import { useAppDispatch } from "../../../../redux/hooks";
import { saveHomeCompetitionCardsAction } from "../../../../redux/slices/reportSlice";

interface ViperMatchDataTableProps {
  isHome?: boolean;
  currentDivision: string;
  currentMatch: string;
  viperMatchData: IViperMatchData[];
  isLoadingViperMatchData: boolean;
}

const tableFields = [
  "Team Name",
  "Wins",
  "Losses",
  "Ties",
  "FP",
  "CAM",
  "UPEFP",
  "CASH",
  "EXC",
  "LIGHT",
  "WRTY",
  "AVGRMR",
];

const ViperMatchDataTable = ({
  isHome = false,
  currentDivision,
  currentMatch,
  isLoadingViperMatchData,
  viperMatchData,
}: ViperMatchDataTableProps) => {
  const dispatch = useAppDispatch();

  const parsedMatchData = useMemo(() => {
    let newMatchData: IViperMatchData[] = [];
    if (currentMatch === "Standing") {
      newMatchData = viperMatchData;
    } else {
      viperMatchData.forEach((match1) => {
        const matchIndex = newMatchData.findIndex(
          (match2) =>
            match2.homeTeamName === match1.awayTeamName ||
            match2.awayTeamName === match1.homeTeamName
        );

        if (matchIndex === -1) {
          newMatchData.push(match1);
        } else {
          // If the match exists, insert it at the found index
          newMatchData.splice(matchIndex, 0, match1);
        }
      });
    }

    return newMatchData.map((matchData) => {
      return Object.entries(matchData)
        .filter(([key]) => key !== "awayTeamName")
        .map(([key, values]) => {
          switch (ViperMatchDataFieldTypes[key.toUpperCase()]) {
            case "Dollar":
              return `$${parseFloat(values).toFixed(2)}`;
            case "Normal":
              return parseFloat(parseFloat(values).toFixed(2));
            case "Percentage":
              return `${parseFloat(values).toFixed(2)}%`;

            default:
              return values;
          }
        });
    });
  }, [currentMatch, viperMatchData]);

  
  const handleHomeCard = () => {
    toast.promise(
      dispatch(saveHomeCompetitionCardsAction({
        cardName: "Viper - 7 Week Series - " + currentMatch + " - " + currentDivision,
        cardType: HomeComeptitionCardType.Viper7WeekSeries,
        cardParams: JSON.stringify({
          match: currentMatch,
          division: currentDivision
        })
      })).unwrap(),
      {
        loading: "Adding card to home screen",
        success: () => {
          return `Added card to home screen.`;
        },
        error: "The card is already added to home screen.",
      }
    );
  }

  return (
    <div className={`flex flex-col gap-[14px] px-6 ${!isHome ? "mt-8" : "w-full"}`}>
      {
      !isHome &&
      <div className="flex gap-2 items-center">
        <div className="bg-viper-primary w-[2px] h-[18px] rounded-[2px]"></div>
        <div className="flex-1 flex justify-between">
          <div className="flex items-end gap-2">
            <div className="text-white font-work text-base not-italic font-medium leading-[150%]">
              {currentMatch}
            </div>
          </div>
            <IconButton
              size={24}
              padding={0}
              className={"hover:bg-transparent flex"}
              onClick={handleHomeCard}
            >
              <HomeEmptyIcon className="w-5 h-5 text-white" />
            </IconButton>
        </div>
      </div>
      }

      <div className="w-full overflow-x-auto scrollbar-thin scrollbar-thumb-[#a1a6c0] scrollbar-track-[#1a1c2e] scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
        <div className="min-w-[1100px] bg-[#484C5EA6] flex border-[1px] border-solid border-viper-mediumDarkGrayishBlue rounded-t-[6px]">
          <div className="w-10 border-r-[1px] border-solid border-viper-mediumDarkGrayishBlue"></div>

          {tableFields.map((field, index) => {
            return (
              <div
                className={classNames(` text-center whitespace-nowrap`, {
                  "w-[180px]": index === 0,
                  "w-20": index > 0,
                  "border-r-[1px] border-solid border-viper-mediumDarkGrayishBlue":
                    index !== tableFields.length - 1,
                })}
                key={index}
              >
                <div className="py-[10px] font-work text-sm not-italic font-normal leading-[150%]">
                  {currentMatch === "Standing" &&
                    index >= 4 &&
                    index !== tableFields.length - 1 &&
                    "AVG"}
                  {field}
                </div>
              </div>
            );
          })}
        </div>

        <div className="mt-[6px] pb-[14px]">
          {isLoadingViperMatchData
            ? [...new Array(16)].map((_, index) => {
                return (
                  <div
                    className={classNames(
                      "min-w-[1100px] h-[41px] bg-[#2F324333] flex border-[1px] border-solid border-viper-mediumDarkGrayishBlue",
                      { "border-b-[0px]": index !== 15 }
                    )}
                    key={index}
                  >
                    <div className="w-10 text-center border-r-[1px] border-solid border-viper-mediumDarkGrayishBlue whitespace-nowrap">
                      <div className="py-[10px] font-work text-sm not-italic font-normal leading-[150%]">
                        {index + 1}
                      </div>
                    </div>
                    {tableFields.map((field, index) => {
                      return (
                        <div
                          className={classNames(
                            ` text-center whitespace-nowrap  h-[41px]`,
                            {
                              "w-[180px]": index === 0,
                              "w-20": index > 0,
                              "border-r-[1px] border-solid border-viper-mediumDarkGrayishBlue":
                                index !== tableFields.length - 1,
                            }
                          )}
                          key={index}
                        >
                          <div className="py-[6px] h-[41px] font-work text-sm not-italic font-normal">
                            <Skeleton
                              width={index === 0 ? 160 : 30}
                              height={21}
                              baseColor="#2F324366"
                              highlightColor="#2F3243"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })
            : parsedMatchData.map((rank, index) => {
                return (
                  <div
                    className={classNames(
                      "min-w-[1100px] bg-[#2F324333] flex border-[1px] border-solid border-viper-mediumDarkGrayishBlue",
                      {
                        "border-b-[0px]": index !== parsedMatchData.length - 1,
                        "bg-[#2F3243FF] mb-2 border-b-[1px] rounded-b-sm":
                          index % 2 === 1 && currentMatch !== "Standing",
                        "rounded-t-sm":
                          index % 2 === 0 && currentMatch !== "Standing",
                      }
                    )}
                    key={index}
                  >
                    <div className="w-10 text-center border-r-[1px] border-solid border-viper-mediumDarkGrayishBlue whitespace-nowrap">
                      <div className="py-[10px] font-work text-sm not-italic font-normal leading-[150%]">
                        {index + 1}
                      </div>
                    </div>
                    {tableFields.map((field, fieldIndex) => {
                      return (
                        <div
                          className={classNames(
                            ` text-center whitespace-nowrap`,
                            {
                              "w-[180px]": fieldIndex === 0,
                              "w-20": fieldIndex > 0,
                              "border-r-[1px] border-solid border-viper-mediumDarkGrayishBlue ":
                                fieldIndex !== tableFields.length - 1,
                            }
                          )}
                          key={fieldIndex}
                        >
                          <div className="py-[10px] px-[10px] font-work text-sm not-italic font-normal leading-[150%] whitespace-nowrap overflow-hidden overflow-ellipsis">
                            {rank[fieldIndex]}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default ViperMatchDataTable;
