import React, { useEffect, useRef, useState } from "react";
import MobilePopup, { MobilePopupPosition } from "../../Common/MobilePopup";
import CheckIcon from "../../icons/CheckIcon";

interface ParameterOption {
  value: string;
  formattedValue: string;
  checked: boolean;
}

interface ParameterData {
  options: ParameterOption[];
  field: string;
  title: string;
}

interface TableauVizParameterDropdownProps {
  parameterKey: string;
  parameterData: ParameterData;
  selectedFilterCategory?: string; // Made optional since it wasn't used in the component
  applyParameter: (field: string, value: string) => void;
}

const TableauVizParameterDropdown: React.FC<
  TableauVizParameterDropdownProps
> = ({ parameterKey, parameterData, applyParameter }) => {
  const [checkboxOptions, setCheckboxOptions] = useState<ParameterOption[]>(
    parameterData.options
  );
  const [checkedItems, setCheckedItems] = useState<ParameterOption[]>(
    parameterData.options.filter((x) => x.checked)
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("");
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    buildCheckBoxOptions();
    setCheckedItems(parameterData.options.filter((x) => x.checked));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameterData]);

  useEffect(() => {
    handleSetStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedItems]);

  const buildCheckBoxOptions = (): void => {
    setCheckboxOptions(parameterData.options);
  };

  const toggleDropdown = (): void => {
    setIsOpen(!isOpen);
  };

  const parameterOptions = (): ParameterOption[] => {
    return checkboxOptions.filter((item) => item.formattedValue.toLowerCase());
  };

  const handleCheckboxChange = (index: number): void => {
    let newCheckedItems = [...parameterData.options];
    newCheckedItems.forEach((i) => (i.checked = false));
    newCheckedItems[index].checked = true;
    setCheckboxOptions(newCheckedItems);
  };

  const handleApply = (): void => {
    toggleDropdown();
    const selectedOption = checkboxOptions.filter((x) => x.checked)[0];
    applyParameter(parameterData.field, selectedOption.value);
  };

  const handleSetStatus = (): void => {
    if (checkedItems.length <= 0) {
      setStatus("Select Option");
    } else if (checkedItems.length === 1) {
      setStatus(`${checkedItems[0].formattedValue}`);
    }
  };

  return (
    <div className="w-full flex flex-col items-start justify-start text-sm font-primary gap-2">
      <p className="input-label text-sm font-primary">{parameterData.title}</p>
      <div
        ref={dropdownRef}
        className={`dropdown ${parameterKey}-dropdown ${isOpen ? "on" : ""}`}
        data-control="checkbox-dropdown"
      >
        <label
          className="flex justify-between items-center px-4 py-2 mb-2 rounded-sm cursor-pointer text-sm font-primary bg-custom-darkTeal !h-10"
          onClick={toggleDropdown}
        >
          {status}
        </label>

        <MobilePopup
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
          backBg="bg-overlay"
          popupBg="bg-thecup-secondary-main"
          popupBorder="ring-0px"
          position={MobilePopupPosition.Bottom}
        >
          {isOpen && (
            <div
              className={`bg-custom-deepCharcoal max-h-height-normal !rounded-none !rounded-t-[16px] dropdown-list px-2 ${parameterKey}`}
            >
              <div className="sticky top-0 p-4 border-b-[1px] border-custom-darkTeal border-solid flex items-center justify-center bg-custom-deepCharcoal">
                <p style={{ color: "white" }} className="text-sm font-primary">
                  {parameterData.title}
                </p>
              </div>

              <div className="bg-custom-deepCharcoal w-full">
                {parameterOptions().map((option, index) => (
                  <div className="w-full" key={index}>
                    <div
                      className="flex items-center px-4 py-3 border-b-[1px] border-custom-darkTeal border-solid"
                      onClick={() => handleCheckboxChange(index)} // Add click handler to the div
                    >
                      <input
                        type="checkbox"
                        className="hidden"
                        value={option.value}
                        checked={option.checked}
                        onChange={() => handleCheckboxChange(index)}
                      />

                      <div
                        className={`w-5 h-5 mr-2 rounded-md border-2 border-primaryText flex items-center justify-center cursor-pointer ${
                          option.checked ? "bg-custom-teal" : ""
                        }`}
                      >
                        {option.checked && (
                          <CheckIcon className="text-primaryText" />
                        )}
                      </div>

                      <span className="select-none text-primaryText font-primary text-sm not-italic font-semibold leading-[120%]">
                        {option.formattedValue}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              <div className="sticky bottom-0 p-4 text-center justify-center bg-custom-deepCharcoal">
                <button
                  type="button"
                  className="apply-filter"
                  onClick={(e) => {
                    e.preventDefault();
                    handleApply();
                  }}
                >
                  Apply Filter
                </button>
              </div>
            </div>
          )}
        </MobilePopup>
      </div>
    </div>
  );
};

export default TableauVizParameterDropdown;
