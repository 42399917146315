import isMobile from "is-mobile";
import { forwardRef, useEffect, useRef, useState } from "react";
import { renderDateChart } from "./helper";

interface DateChartProps {
  isFullScreen: boolean;
  isFitScreen: boolean;
  scale: number;
  loading: boolean;
  className: string;
  chartData: any | any[];
  groupByDate: string;
}

const DateChart = forwardRef<HTMLDivElement, DateChartProps>(
  (
    {
      scale,
      isFullScreen,
      isFitScreen,
      loading,
      className,
      chartData,
      groupByDate,
    }: DateChartProps,
    ref
  ) => {
    const chartRef = useRef<HTMLDivElement | null>(null);

    const rowIncrement = isMobile() ? 10 : 20; // Number of rows to load on each scroll
    const [visibleRows, setVisibleRows] = useState(rowIncrement); // Start with 20 rows
    const [chartWidth, setChartWidth] = useState<number>(0);

    // Handle scroll event to trigger lazy loading
    const handleScroll = () => {
      const table = document.querySelector(".report-chart");
      if (table) {
        const { scrollTop, scrollHeight, clientHeight } = table;
        if (scrollTop + clientHeight >= scrollHeight - 50) {
          loadMoreRows();
        }
      }
    };

    const loadMoreRows = () => {
      if (visibleRows < chartData.length) {
        setVisibleRows((prev) =>
          Math.min(prev + rowIncrement, chartData.length)
        );
      }
    };

    useEffect(() => {
      const table = document.querySelector(".report-chart");
      table?.addEventListener("scroll", handleScroll);
      return () => {
        table?.removeEventListener("scroll", handleScroll);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleRows, chartData]);

    useEffect(() => {
      setVisibleRows(rowIncrement);
    }, [rowIncrement, chartData]);

    useEffect(() => {
      if (chartRef.current) {
        setChartWidth(chartRef.current.clientWidth);
      }
    }, [chartRef, isFullScreen]);

    return (
      <div
        className={`report-chart w-full bg-custom-deepCharcoal ${className} overflow-auto 
          ${isFitScreen ? "overflow-hidden" : ""}`}
        style={{
          height: isFullScreen
            ? window.innerHeight - 40
            : window.innerHeight - 224,
        }}
        ref={(el) => {
          chartRef.current = el;
          if (ref) {
            if (typeof ref === "function") {
              ref(el);
            } else {
              ref.current = el;
            }
          }
        }}
      >
        <div className={`h-full flex p-sm ${isFitScreen ? "" : "gap-sm"}`}>
          {renderDateChart(
            scale,
            isFitScreen,
            isFullScreen,
            loading,
            rowIncrement,
            visibleRows,
            chartData,
            groupByDate,
            chartWidth
          )}
        </div>
      </div>
    );
  }
);

export default DateChart;
