import { useEffect } from "react";
import toast from "react-hot-toast";

import TheCupBracket from "../Common/TheCupBracket";
import IconButton from "../../../Common/IconButton";

import StarIcon from "../../../icons/StarIcon";
import HomeEmptyIcon from "../../../icons/HomeEmptyIcon";

import { generateCupBracketRounds } from "../helper/bracket";

import { HomeComeptitionCardType } from "../../../../const/enums/HomeCompetitionCardType";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { saveHomeCompetitionCardsAction } from "../../../../redux/slices/reportSlice";
import { getCupBracketListAction, getHomeCupBracketListAction } from "../../../../redux/slices/cupSlice";

const myMatch = {
  id: "W8-Little Rock 2024-Colorado Springs 2024",
  bracketId: 169,
  teams: [
    {
      id: "Little Rock 2024",
      name: "Little Rock 2024",
      score: -155,
      baselineScore: 2136.4,
      matchScore: 1981.4,
      isWinner: false,
    },
    {
      id: "Colorado Springs 2024",
      name: "Colorado Springs 2024",
      score: -53.9,
      baselineScore: 1129.8,
      matchScore: 1075.9,
      isWinner: true,
    },
  ],
};

interface MyMatchUpProps {
  isHome?: boolean;
  division?: string;
  card_name?: string;
}

const MyMatchUp = ({ isHome = false, division = "", card_name = "" }: MyMatchUpProps) => {
  const { bracketList, currentDivision, homeCards } = useAppSelector((state) => state.cup);

  const firstRound = !isHome ? generateCupBracketRounds(bracketList) : (homeCards[card_name] ? generateCupBracketRounds(homeCards[card_name]) : []);
  const title = firstRound[0]?.[0]?.title || "No Title Available";
  const seed = firstRound[0]?.[0]?.seeds[0] || null;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (division && isHome)
      dispatch(getHomeCupBracketListAction({
        card_name: card_name,
        division
      }));
    else
      dispatch(getCupBracketListAction(currentDivision));
  }, [currentDivision, isHome, card_name, division, dispatch]);

  const handleHomeCard = () => {
    toast.promise(
      dispatch(saveHomeCompetitionCardsAction({
        cardName: "The Cup - My Match Up - " + currentDivision,
        cardType: HomeComeptitionCardType.TheCupMyMatchUp,
        cardParams: JSON.stringify({
          division: currentDivision
        })
      })).unwrap(),
      {
        loading: "Adding card to home screen",
        success: () => {
          return `Added card to home screen.`;
        },
        error: "The card is already added to home screen.",
      }
    );
  }

  return (
    <div className={`flex flex-col gap-4 px-5 ${!isHome ? "mt-4" : "w-full"}`}>
      {!isHome &&
      <div className="flex justify-between">
        <div className="font-plus text-white text-lg not-italic font-semibold leading-[150%]">
          My Match Up
        </div>
        <IconButton
          size={24}
          padding={0}
          className={"hover:bg-transparent flex"}
          onClick={handleHomeCard}
        >
          <HomeEmptyIcon className="w-5 h-5 text-white" />
        </IconButton>
      </div>
      }
      {!seed ? (
        <div className="m-auto flex items-center justify-between mt-10">
          <div className="w-8 h-8 border-2 border-t-thecup-primary-main border-gray-300 rounded-full animate-spin"></div>
        </div>
      ) : (
        <div className="flex flex-col gap-[15px] rounded-[15px] bg-thecup-darkGrayishBlue pt-[18px] px-[20px]">
          <div className="flex gap-2 items-center">
            <StarIcon className="w-[15px] h-[15px] text-thecup-secondary-main" />
            <div className="text-thecup-secondary-main font-plus text-sm not-italic font-semibold leading-[150%]">
              {title}
            </div>
          </div>

          <TheCupBracket seed={myMatch} />
        </div>
      )}
    </div>
  );
};
export default MyMatchUp;
