const InfoIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      className={className}
    >
      <path
        d="M9.10139 0.121094C4.39558 0.121094 0.587158 3.92909 0.587158 8.63533C0.587158 13.3411 4.39515 17.1496 9.10139 17.1496C13.8072 17.1496 17.6156 13.3416 17.6156 8.63533C17.6156 3.92958 13.8076 0.121094 9.10139 0.121094ZM9.97573 12.0151C9.97573 12.2841 9.58348 12.553 9.10156 12.553C8.59722 12.553 8.23863 12.2841 8.23863 12.0151V7.74512C8.23863 7.43133 8.59726 7.21834 9.10156 7.21834C9.58348 7.21834 9.97573 7.43133 9.97573 7.74512V12.0151ZM9.10159 6.18735C8.58605 6.18735 8.18262 5.80631 8.18262 5.3804C8.18262 4.95452 8.58608 4.58468 9.10159 4.58468C9.60593 4.58468 10.0094 4.95452 10.0094 5.3804C10.0094 5.80631 9.6059 6.18735 9.10159 6.18735Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default InfoIcon;
