import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { getMatchDataInfoAction } from "../../../../redux/slices/viperSlice";

import ViperMatchDataTable from "./ViperMatchDataTable";

interface ViperMatchDataTableCardProps {
    match: string;
    division: string;
}

const ViperMatchDataTableCard = ({
    match,
    division
}: ViperMatchDataTableCardProps) => {
    const dispatch = useAppDispatch();
    const {
      viperMatchData,
      isLoadingViperMatchData,
    } = useAppSelector((state) => state.viper);
  
    useEffect(() => {
      dispatch(
        getMatchDataInfoAction({
          division: division,
          matchName: match,
        })
      );
    }, [match, division, dispatch]);

    return <ViperMatchDataTable
      isHome={true}
      currentDivision={division}
      currentMatch={match}
      isLoadingViperMatchData={isLoadingViperMatchData}
      viperMatchData={viperMatchData}
    />
}

export default ViperMatchDataTableCard;
