import { ViperMatchDataFieldType } from "../types/ViperMatchDataFieldType";

export const ViperMatchLists = [
  "Standing",
  "Game 1",
  "Game 2",
  "Game 3",
  "Game 4",
  "Game 5",
  "Game 6",
  "Game 7",
];

export const ViperDivisionList = [
  "PURPLE",
  "BLACK",
  "VENOM",
  "GREEN",
  "BLUE",
  "RED",
  "WHITE",
];

export const ViperBracketMatchDetail = [
  {
    name: "QUARTER FINALS",
    title: "Quarter Finals",
    start: "2024-07-01 00:00:00",
    end: "2024-07-06 00:00:00",
    matchId: 8,
  },

  {
    name: "SEMI FINALS",
    title: "Semi Finals",
    start: "2024-07-08 00:00:00",
    end: "2024-07-13 00:00:00",
    matchId: 9,
  },

  {
    name: "FINALS",
    title: "Championship",
    start: "2024-07-08 00:00:00",
    end: "2024-07-13 00:00:00",
    matchId: 10,
  },
];

export const ViperMatchDataFieldTypes: {
  [key: string]: ViperMatchDataFieldType;
} = {
  WINS: "Normal",
  FP: "Normal",
  CAM: "Normal",
  UPEFP: "Normal",
  CASH: "Normal",
  EXC: "Percentage",
  LIGHT: "Percentage",
  WRTY: "Percentage",
  AVGRMR: "Dollar",
};
