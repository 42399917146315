import { UserGroupIcon } from "@heroicons/react/16/solid";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline";
import { useSearchParams } from "react-router-dom";

interface Props {
  board: any[];
  mainProfile: any;
  orderBy: string;
  orderDir: string;
}

export default function RepBoard({
  mainProfile,
  board,
  orderBy,
  orderDir,
}: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const active = board.findIndex((m) => m.entity_value === mainProfile?.name);

  const handleSort = (field: string) => {
    if (orderBy === field) {
      searchParams.set("order_by", orderBy);
      searchParams.set("order_dir", orderDir === "asc" ? "desc" : "asc");
    } else {
      searchParams.set("order_by", field);
      searchParams.set("order_dir", "asc");
    }
    setSearchParams(searchParams, { replace: true });
  };

  return mainProfile && board ? (
    <div className="px-3 py-3">
      <div className="font-bold mb-6">{mainProfile.category} Board</div>
      <table className="w-full">
        <thead className="">
          <tr className="border-b border-neutral-800">
            <th className="text-neutral-100 text-center text-xs font-normal py-2 px-1">
              RK
            </th>
            <th className="text-left text-xs font-normal px-1">
              {mainProfile.category}
            </th>
            <th
              className="text-left text-xs font-normal px-1 w-12"
              onClick={() => handleSort("sales")}
            >
              <div className="flex items-center gap-1">
                <span>Sales</span>
                <SortIcon active={orderBy === "sales"} dir={orderDir} />
              </div>
            </th>
            <th
              className="text-xs font-normal px-1 w-12"
              onClick={() => handleSort("installs")}
            >
              <div className="flex items-center gap-1">
                <span>Installs</span>
                <SortIcon active={orderBy === "installs"} dir={orderDir} />
              </div>
            </th>
            <th
              className="text-xs font-normal px-1 w-16"
              onClick={() => handleSort("clean_sales")}
            >
              <div className="flex items-center gap-1">
                <span>Clean Sales</span>
                <SortIcon active={orderBy === "clean_sales"} dir={orderDir} />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {board.map((row, index) => (
            <tr key={row.entity_value} className="text-sm">
              <td className="p-0 text-center">
                <div
                  className={`px-1 py-3 rounded-l border-l-4 overflow-hidden ${
                    index === active
                      ? "bg-neutral-900 border-primary"
                      : "border-transparent"
                  }`}
                >
                  <span
                    className={`px-1 text-xs rounded ${
                      index === active
                        ? "text-neutral-950 bg-white"
                        : "border border-[#655F5F]"
                    }`}
                  >
                    {row[`${orderBy}_rank`]}
                  </span>
                </div>
              </td>
              <td
                className={`px-1 ${active === index ? "bg-neutral-900" : ""}`}
              >
                <div className="flex gap-2 items-center">
                  {row.photo ? (
                    <img
                      src={row.photo}
                      className="rounded-full size-6 border border-neutral-900"
                      alt=""
                    />
                  ) : (
                    <UserGroupIcon className="size-6" />
                  )}
                  <span className={active === index ? "font-semibold" : ""}>
                    {row.entity_value}
                  </span>
                </div>
              </td>
              <td
                className={`px-1 ${active === index ? "bg-neutral-900" : ""}`}
              >
                {row.sales}
              </td>
              <td
                className={`px-1 ${active === index ? "bg-neutral-900" : ""}`}
              >
                {row.installs}
              </td>
              <td
                className={`text-right px-2 rounded-r ${
                  active === index ? "bg-neutral-900" : ""
                }`}
              >
                {row.clean_sales}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <></>
  );
}

interface SortIconProps {
  dir: string;
  active: boolean;
}

const SortIcon = ({ dir, active }: SortIconProps) =>
  active ? (
    dir === "asc" ? (
      <ArrowUpIcon className="size-3" />
    ) : (
      <ArrowDownIcon className="size-3" />
    )
  ) : (
    <></>
  );
