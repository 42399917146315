import { useEffect, useState } from "react";
import { ISeedProps } from "react-brackets";
import { CSSTransition } from "react-transition-group";

import { IViperBracketData } from "../../../../interfaces/viperBracketData";

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { getBracketDataAction } from "../../../../redux/slices/viperSlice";

import ViperBracketInfo from "./ViperBracketInfo";
import ViperBrackets from "./ViperBrackets";

interface ViperBracketsCardProps {
    division: string;
}

const ViperBracketsCard = ({
    division
}: ViperBracketsCardProps) => {
  const dispatch = useAppDispatch();

  const [showBracketDetail, setShowBracketDetail] = useState(false);
  const [bracketDetail, setBracketDetail] = useState<IViperBracketData>();
  const [seedDetail, setSeedDetail] = useState<ISeedProps>();

  const { viperBracketData } = useAppSelector(
    (state) => state.viper
  );

  useEffect(() => {
    dispatch(
      getBracketDataAction({
        division
      })
    );
  }, [division, dispatch]);

  return <>
        <CSSTransition
            in={showBracketDetail}
            timeout={200}
            classNames={{
                enter: "fade-enter",
                enterActive: "fade-enter-active",
                exit: "fade-exit",
                exitActive: "fade-exit-active",
            }}
            unmountOnExit
        >
            <ViperBracketInfo
            bracketDetail={bracketDetail}
            seedDetail={seedDetail}
            onBack={() => {
                setShowBracketDetail(false);
            }}
            />
        </CSSTransition>
        <ViperBrackets
            isHome={true}
            brackets={viperBracketData}
            setBracketDetail={(bracket: IViperBracketData) => {
                setShowBracketDetail(true);
                setBracketDetail(bracket);
            }}
            setSeedDetail={(seed: ISeedProps) => {
                setSeedDetail(seed);
            }}
        />
    </>
}

export default ViperBracketsCard;
