import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getMatchDataInfoAction } from "../../../redux/slices/viperSlice";

import ViperDivisionList from "./Common/ViperDivisionList";
import ViperMyPerformance from "./Common/ViperMyPerformance";
import ViperMatchDataTable from "./SevenWeeks/ViperMatchDataTable";
import ViperMatchListTab from "./ViperMatchListTabs";

const ViperSevenWeeksSeriesTab = () => {
  const dispatch = useAppDispatch();
  const {
    currentMatch,
    currentDivision,
    viperMatchData,
    isLoadingViperMatchData,
  } = useAppSelector((state) => state.viper);

  useEffect(() => {
    dispatch(
      getMatchDataInfoAction({
        division: currentDivision,
        matchName: currentMatch,
      })
    );
  }, [currentMatch, currentDivision, dispatch]);

  return (
    <>
      <ViperMatchListTab />

      <ViperDivisionList />

      <ViperMatchDataTable
        currentDivision={currentDivision}
        currentMatch={currentMatch}
        isLoadingViperMatchData={isLoadingViperMatchData}
        viperMatchData={viperMatchData}
      />

      <ViperMyPerformance />
    </>
  );
};

export default ViperSevenWeeksSeriesTab;
