import toast from "react-hot-toast";
import IconButton from "../../../Common/IconButton";
import HomeEmptyIcon from "../../../icons/HomeEmptyIcon";
import { saveHomeCompetitionCardsAction } from "../../../../redux/slices/reportSlice";
import { HomeComeptitionCardType } from "../../../../const/enums/HomeCompetitionCardType";
import { useAppDispatch } from "../../../../redux/hooks";

const performance = [
  { label: "AVG FP", value: 87 },
  { label: "AVG CAM", value: 620 },
  { label: "AVG UPEFP", value: 49.2 },
  { label: "AVG CASH", value: 318.9 },
  { label: "AVG EXC", value: "76%" },
  { label: "AVG LIGHT", value: "41%" },
  { label: "AVG WRTY", value: "96%" },
  { label: "AVGRMR", value: "$104.52" },
  { label: "AVGRMR", value: "$104.52" },
];

interface ViperMyPerformanceProps {
  isHome?: boolean;
}

const ViperMyPerformance = ({ isHome }: ViperMyPerformanceProps) => {
  const dispatch = useAppDispatch();

  const handleHomeCard = () => {
    toast.promise(
      dispatch(saveHomeCompetitionCardsAction({
        cardName: "Viper - My Performance",
        cardType: HomeComeptitionCardType.ViperMyPerformance,
        cardParams: "{}"
      })).unwrap(),
      {
        loading: "Adding card to home screen",
        success: () => {
          return `Added card to home screen.`;
        },
        error: "The card is already added to home screen.",
      }
    );
  }

  return (
    <div className={`flex flex-col gap-[14px] px-6 ${!isHome ? "mt-8" : "w-full"}`}>
      {!isHome && <div className="flex gap-2 items-center">
        <div className="bg-viper-primary w-[2px] h-[18px] rounded-[2px]"></div>
        <div className="flex justify-between flex-1">
          <div className="flex items-end gap-2">
            <div className="text-white font-work text-base not-italic font-medium leading-[150%]">
              My Performance
            </div>
          </div>
          {
          !isHome &&
            <IconButton
              size={24}
              padding={0}
              className={"hover:bg-transparent flex"}
              onClick={handleHomeCard}
            >
              <HomeEmptyIcon className="w-5 h-5 text-white" />
            </IconButton>
          }
        </div>
      </div>}

      <div
        className="flex border-[1px] border-solid border-viper-mediumDarkGrayishBlue rounded-[6px] bg-viper-darkestGrayishBlue 
                  flex-col
                  sm:flex-col 
                  md:flex-row"
      >
        <div
          className="flex p-5 gap-4 border-solid border-viper-mediumDarkGrayishBlue min-w-[240px]
                flex-row border-b-[1px]
                sm:flex-row sm:border-b-[1px]
                md:flex-col md:border-b-[0px] md:border-r-[1px] md:items-center"
        >
          <img
            src="https://www.figma.com/file/OZyH8ET9kjAggN5yQkMmek/image/657e16a28e19f8f50f14ebf4a6ced12043840715?fuid=1215720084589520081"
            alt="Profile"
            srcSet=""
            className="rounded-[50%] border-solid border-[2px] border-viper-mediumDarkGrayishBlue 
                      w-[50px] h-[50px]
                      sm:w-[50px] sm:h-[50px]
                      md:w-[120px] md:h-[120px]"
          />

          <div
            className="flex flex-col 
                      items-start
                      md:items-center"
          >
            <div className="text-white font-work text-base not-italic font-medium leading-[150%]">
              Valentin Pierrat
            </div>

            <div className="text-viper-softGrayishBlue font-work text-sm not-italic font-normal leading-[150%]">
              B Owens Team
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full">
          <div className="grid grid-cols-3 border-b-[1px] border-solid border-viper-mediumDarkGrayishBlue">
            <div className="flex flex-col border-r-[1px] border-solid border-viper-mediumDarkGrayishBlue pt-3 pb-[14px] ">
              <div className="text-white text-center font-work text-[15px] not-italic font-medium leading-[150%] uppercase">
                {performance[0].label}
              </div>
              <div className="text-viper-softGrayishBlue text-center font-work text-[15px] not-italic font-medium leading-[150%] uppercase">
                {performance[0].value}
              </div>
            </div>
            <div className="flex flex-col border-r-[1px] border-solid border-viper-mediumDarkGrayishBlue pt-3 pb-[14px] ">
              <div className="text-white text-center font-work text-[15px] not-italic font-medium leading-[150%] uppercase">
                {performance[1].label}
              </div>
              <div className="text-viper-softGrayishBlue text-center font-work text-[15px] not-italic font-medium leading-[150%] uppercase">
                {performance[1].value}
              </div>
            </div>

            <div className="flex flex-col pt-3 pb-[14px]">
              <div className="text-white text-center font-work text-[15px] not-italic font-medium leading-[150%] uppercase">
                {performance[2].label}
              </div>
              <div className="text-viper-softGrayishBlue text-center font-work text-[15px] not-italic font-medium leading-[150%] uppercase">
                {performance[2].value}
              </div>
            </div>
          </div>

          <div className="grid grid-cols-3 border-b-[1px] border-solid border-viper-mediumDarkGrayishBlue">
            <div className="flex flex-col border-r-[1px] border-solid border-viper-mediumDarkGrayishBlue pt-3 pb-[14px] ">
              <div className="text-white text-center font-work text-[15px] not-italic font-medium leading-[150%] uppercase">
                {performance[3].label}
              </div>
              <div className="text-viper-softGrayishBlue text-center font-work text-[15px] not-italic font-medium leading-[150%] uppercase">
                {performance[3].value}
              </div>
            </div>
            <div className="flex flex-col border-r-[1px] border-solid border-viper-mediumDarkGrayishBlue pt-3 pb-[14px] ">
              <div className="text-white text-center font-work text-[15px] not-italic font-medium leading-[150%] uppercase">
                {performance[4].label}
              </div>
              <div className="text-viper-softGrayishBlue text-center font-work text-[15px] not-italic font-medium leading-[151%] uppercase">
                {performance[4].value}
              </div>
            </div>

            <div className="flex flex-col pt-3 pb-[14px]">
              <div className="text-white text-center font-work text-[15px] not-italic font-medium leading-[150%] uppercase">
                {performance[5].label}
              </div>
              <div className="text-viper-softGrayishBlue text-center font-work text-[15px] not-italic font-medium leading-[152%] uppercase">
                {performance[5].value}
              </div>
            </div>
          </div>

          <div className="grid grid-cols-3">
            <div className="flex flex-col border-r-[1px] border-solid border-viper-mediumDarkGrayishBlue pt-3 pb-[14px] ">
              <div className="text-white text-center font-work text-[15px] not-italic font-medium leading-[150%] uppercase">
                {performance[6].label}
              </div>
              <div className="text-viper-softGrayishBlue text-center font-work text-[15px] not-italic font-medium leading-[150%] uppercase">
                {performance[6].value}
              </div>
            </div>
            <div className="flex flex-col border-r-[1px] border-solid border-viper-mediumDarkGrayishBlue pt-3 pb-[14px] ">
              <div className="text-white text-center font-work text-[15px] not-italic font-medium leading-[150%] uppercase">
                {performance[7].label}
              </div>
              <div className="text-viper-softGrayishBlue text-center font-work text-[15px] not-italic font-medium leading-[151%] uppercase">
                {performance[7].value}
              </div>
            </div>

            <div className="flex flex-col pt-3 pb-[14px]">
              <div className="text-white text-center font-work text-[15px] not-italic font-medium leading-[150%] uppercase">
                {performance[8].label}
              </div>
              <div className="text-viper-softGrayishBlue text-center font-work text-[15px] not-italic font-medium leading-[152%] uppercase">
                {performance[8].value}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViperMyPerformance;
