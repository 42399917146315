import MyMatchUp from "./Overview/MyMatchUp";
import OtherMatchUp from "./Overview/OtherMatchUp";
import PrizeInfo from "./Overview/PrizeInfo";

const TheCupOverview = () => {
  return (
    <>
      <MyMatchUp />

      <PrizeInfo />

      <OtherMatchUp />
    </>
  );
};

export default TheCupOverview;
