import { Location } from "react-router-dom";
import { getQueryParam } from ".";
import { ReportType } from "../const/enums/ReportType";
import { IReportList } from "../interfaces/reportList";
import { ISavedFilter } from "../interfaces/savedFilter";

/**
 * Processes a list of Tableau reports to find and format a specific report
 * based on its alias.
 * @param reports - Array of report categories containing reports.
 * @param reportAlias - The alias of the report to find.
 * @returns The formatted report object or null if not found.
 */
export const processTableauReport = (
  reports: IReportList[],
  reportAlias: string | null
) => {
  // If report alias is empty or reports array is empty, return null
  if (!reportAlias || reports.length === 0) return null;

  // Flatten the nested report categories into a single array of reports
  const flattenedReports = reports.flatMap((category) => category.reports);

  // Find the report by its alias
  const foundReport = flattenedReports.find(
    (report) => report.alias === reportAlias
  );

  if (foundReport) {
    // If the report type is Custom, return the found report directly
    if (foundReport.reportType === ReportType.Custom) return foundReport;

    // Parse the report URL to replace the domain
    const parseReport = {
      ...foundReport,
      reportUrl: foundReport.reportUrl.replace(
        "10az.online.tableau.com",
        "report.vspn.app"
      ),
    };

    return parseReport; // Return the modified report object
  }

  return null; // Return null if the report was not found
};

/**
 * Retrieves the report alias from the current location's query parameters.
 * @param location - The current location object from react-router-dom.
 * @returns The report alias from the query parameters or null if not found.
 */
export const getReportAliasFromLocation = (location: Location<any>) => {
  if (!location.search) return null;

  // Extract alias from query params
  const reportAlias = getQueryParam(location, "alias");

  return reportAlias; // Return the extracted alias
};

/**
 * Parses a field name to return a human-readable value.
 * @param fieldName - The field name to be parsed.
 * @returns The parsed value as a string.
 */
export const parseReportFilterName = (fieldName: string) => {
  if (fieldName === "0.0") {
    return "False"; // Return "False" for "0.0"
  } else if (fieldName === "1.0") {
    return "True"; // Return "True" for "1.0"
  } else {
    return fieldName; // Return the original field name for other values
  }
};

/**
 * Converts selected filters into URL search parameters.
 * @param selectedFilters - An object containing selected filters.
 * @returns A string representation of the URL search parameters.
 */
export const toURLSearchParams = (selectedFilters: ISavedFilter) => {
  const params = new URLSearchParams();
  for (const key in selectedFilters) {
    const value = selectedFilters[key];
    if (Array.isArray(value)) {
      // If the value is an array, append each item
      value.forEach((item) => params.append(key, item));
    } else {
      // Otherwise, append the single value
      params.append(key, value);
    }
  }
  return params.toString(); // Return the constructed query string
};
