import isMobile from "is-mobile";

const TheCupPoster = ({ isVisible }: { isVisible: boolean }) => {
  return (
    <div
      className={`w-full relative pt-4 px-[10px] transition-all duration-300 ${
        isVisible
          ? !isMobile()
            ? "opacity-100 h-[360px]"
            : "opacity-100 h-[200px]"
          : "opacity-0 h-[0px]"
      }`}
    >
      <img
        src="/assets/imgs/TheCup/thecup_bg.png"
        alt=""
        className="w-full h-full object-cover bg-transparent rounded-[20px]"
      />
    </div>
  );
};

export default TheCupPoster;
