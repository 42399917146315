import classNames from "classnames";
import { ISeedProps, SeedItem, SeedTeam } from "react-brackets";
import { ViperMatchDataFieldTypes } from "../../../../data/Viper";
import { IViperBracketData } from "../../../../interfaces/viperBracketData";
import ArrowPageRight from "../../../icons/ArrowPageRight";
import ViperBattleIcon from "../../../icons/ViperBattleIcon";
import { generateMatchDetail } from "../helper/bracket";

interface ViperBracketInfoProps {
  seedDetail?: ISeedProps;
  bracketDetail?: IViperBracketData;
  onBack: () => void;
}

const ViperBracketInfo = ({
  bracketDetail,
  seedDetail,
  onBack,
}: ViperBracketInfoProps) => {
  const parsedData = (() => {
    return (
      bracketDetail &&
      Object.entries(generateMatchDetail(bracketDetail)).map(
        ([key, values]) => {
          switch (ViperMatchDataFieldTypes[key]) {
            case "Dollar":
              return [`$${values[0]}`, `$${values[1]}`];
            case "Normal":
              return values;
            case "Percentage":
              return [`${values[0]}%`, `${values[1]}%`];

            default:
              return values;
          }
        }
      )
    );
  })();

  const homeTeam = seedDetail ? seedDetail.teams[0] : {};
  const awayTeam = seedDetail ? seedDetail.teams[1] : {};

  return (
    <div className="p-6 flex flex-col items-center">
      <div className="flex justify-between gap-3">
        <div className="flex items-center gap-[6px] relative">
          <div className="w-[69px] flex items-center justify-center">
            <div
              className="w-10 h-10 flex items-center justify-center rounded-[50%] bg-viper-darkGrayishBlue active:bg-viper-mediumDarkGrayishBlue transition-[300ms] cursor-pointer"
              onClick={onBack}
            >
              <ArrowPageRight className="text-viper-softGrayishBlue w-4 h-4 rotate-180" />
            </div>
          </div>
        </div>

        <SeedItem className="flex !bg-transparent !shadow-none items-center gap-[6px] justify-between pr-[32px]">
          <div className="flex flex-col gap-[3px] w-full">
            <SeedTeam className="bg-viper-darkGrayishBlue !rounded-t-[6px] !p-[0]">
              <div className="select-none text-viper-softGrayishBlue rounded-t-[6px] flex items-center justify-center w-10 h-10 text-center font-work text-base not-italic font-medium leading-[150%]">
                {homeTeam.id}
              </div>

              <div className="select-none overflow-hidden overflow-ellipse bg-viper-darkestGrayishBlue text-left px-4 w-full h-[38px] font-work text-[15px] not-italic font-medium leading-[150%] py-[7px] whitespace-nowrap">
                {homeTeam.name ? homeTeam.name : "----"}
              </div>

              <div
                className={classNames(
                  "text-softGrayishBlue rounded-t-[6px] flex items-center justify-center w-10 h-10 text-center font-work text-base not-italic font-medium leading-[150%] select-none",
                  {
                    "bg-viper-primary": homeTeam.isWinner,
                    "bg-viper-darkGrayishBlue": !homeTeam.isWinner,
                  }
                )}
              >
                {homeTeam.score}
              </div>
            </SeedTeam>

            <SeedTeam className="bg-viper-darkGrayishBlue !rounded-b-[6px] !p-[0]">
              <div className="select-none text-viper-softGrayishBlue rounded-b-[6px] flex items-center justify-center w-10 h-10 text-center font-work text-base not-italic font-medium leading-[150%]">
                {awayTeam.id}
              </div>

              <div className="select-none overflow-hidden overflow-ellipse bg-viper-darkestGrayishBlue text-left px-4 w-full h-[38px] font-work text-[15px] not-italic font-medium leading-[150%] py-[7px] whitespace-nowrap">
                {awayTeam.name ? awayTeam.name : "----"}
              </div>

              <div
                className={classNames(
                  "text-softGrayishBlue rounded-b-[6px] flex items-center justify-center w-10 h-10 text-center font-work text-base not-italic font-medium leading-[150%] select-none",
                  {
                    "bg-viper-primary": awayTeam.isWinner,
                    "bg-viper-darkGrayishBlue": !awayTeam.isWinner,
                  }
                )}
              >
                {awayTeam.score}
              </div>
            </SeedTeam>
          </div>
        </SeedItem>
      </div>

      <div className="flex w-full mt-10 flex-col gap-4 p-6 rounded-[6px] border-[1px] border-solid border-viper-mediumDarkGrayishBlue bg-[#202332] [box-shadow:2px_2px_10px_0px_rgba(195,_47,_121,_0.60)]">
        <div className="flex gap-2 items-center">
          <div className="bg-viper-primary w-[2px] h-[18px] rounded-[2px]"></div>

          <div className="flex items-end gap-2">
            <div className="text-white font-work text-base not-italic font-medium leading-[150%]">
              Game Insights
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-sm">
          <div className="flex items-center bg-viper-mediumDarkGrayishBlue rounded-[3px] p-sm">
            <div className="text-white font-work text-sm not-italic font-medium leading-[150%] flex-1 text-center whitespace-nowrap overflow-hidden overflow-ellipsis">
              {homeTeam.name}
            </div>
            <ViperBattleIcon className="text-white" />
            <div className="text-white font-work text-sm not-italic font-medium leading-[150%] flex-1 text-center whitespace-nowrap overflow-hidden overflow-ellipsis">
              {awayTeam.name}
            </div>
          </div>

          <div className="flex flex-col border-solid border-[1px] border-viper-mediumDarkGrayishBlue">
            {bracketDetail &&
              Object.entries(generateMatchDetail(bracketDetail)).map(
                ([key, values], index) => (
                  <div
                    key={key}
                    className={classNames("grid grid-cols-3", {
                      "bg-viper-darkGrayishBlue": index === 0,
                      "border-solid border-t-[1px] border-viper-mediumDarkGrayishBlue":
                        index !== 0,
                    })}
                  >
                    <div className={"font-bold text-center p-[11px] m-auto"}>
                      <div
                        className={classNames(
                          "font-work text-sm not-italic font-medium leading-[150%]",
                          {
                            "flex items-center justify-center w-6 h-6":
                              index === 0,
                            "bg-viper-secondary rounded-[50%] text-viper-darkGrayishBlue":
                              index === 0 && values[0] > values[1],
                            "bg-viper-mediumDarkGrayishBlue rounded-[50%] text-white":
                              index === 0 && values[0] < values[1],

                            "text-viper-secondary":
                              values[0] > values[1] && index > 0,
                          }
                        )}
                      >
                        {parsedData && parsedData[index][0]}
                      </div>
                    </div>

                    <div
                      className={classNames(
                        "font-work text-sm not-italic font-medium leading-[150%]",
                        "text-viper-softGrayishBlue text-center p-[11px] ",
                        "border-solid border-x-[1px] border-viper-mediumDarkGrayishBlue bg-viper-darkGrayishBlue"
                      )}
                    >
                      {key}
                    </div>

                    <div className="text-white text-center p-[11px] m-auto">
                      <div
                        className={classNames(
                          "font-work text-sm not-italic font-medium leading-[150%]",
                          {
                            "flex items-center justify-center w-6 h-6":
                              index === 0,
                            "bg-viper-secondary rounded-[50%] text-viper-darkGrayishBlue":
                              index === 0 && values[0] < values[1],
                            "bg-viper-mediumDarkGrayishBlue rounded-[50%] text-white":
                              index === 0 && values[0] > values[1],

                            "text-viper-secondary":
                              values[0] < values[1] && index > 0,
                          }
                        )}
                      >
                        {parsedData && parsedData[index][1]}
                      </div>
                    </div>
                  </div>
                )
              )}
          </div>
        </div>
      </div>

      <div className="flex w-[80%] flex-col mt-10 border-[1px] border-solid border-viper-mediumDarkGrayishBlue bg-[#252838]">
        <div className="font-work px-2 py-[6px] border-b-[1px] border-solid border-viper-mediumDarkGrayishBlue text-center text-viper-softGrayishBlue text-[11px] not-italic font-normal uppercase">
          TIE BREAK MATCH REP SCORE
        </div>

        <div className="flex gap-1 px-2 py-[6px] items-center justify-center border-b-[1px] border-solid border-viper-mediumDarkGrayishBlue bg-viper-darkGrayishBlue">
          <div className="text-white text-center font-work text-[11px] not-italic font-normal uppercase width-20">
            {bracketDetail?.tieBreakMatchRepScore}
          </div>
          <div className="text-viper-softGrayishBlue text-center font-work text-[11px] not-italic font-normal uppercase width-20">
            VS
          </div>
          <div className="text-white text-center font-work text-[11px] not-italic font-normal uppercase width-20">
            {bracketDetail?.opponentTieBreakMatchRepScore}
          </div>
        </div>

        <div className="font-work px-2 py-[6px] border-b-[1px] border-solid border-viper-mediumDarkGrayishBlue text-center text-viper-softGrayishBlue text-[11px] not-italic font-normal uppercase">
          TIE BREAK YTD CASH POINTS
        </div>

        <div className="flex gap-1 px-2 py-[6px] items-center justify-center border-b-[1px] border-solid border-viper-mediumDarkGrayishBlue bg-viper-darkGrayishBlue">
          <div className="text-white text-center font-work text-[11px] not-italic font-normal uppercase width-20">
            {bracketDetail?.tieBreakYtdCashPoints}
          </div>
          <div className="text-viper-softGrayishBlue text-center font-work text-[11px] not-italic font-normal uppercase width-20">
            VS
          </div>
          <div className="text-white text-center font-work text-[11px] not-italic font-normal uppercase width-20">
            {bracketDetail?.opponentTieBreakYtdCashPoints}
          </div>
        </div>

        <div className="font-work px-2 py-[6px] border-b-[1px] border-solid border-viper-mediumDarkGrayishBlue text-center text-viper-softGrayishBlue text-[11px] not-italic font-normal uppercase">
          TIE BREAK YTD REP SCORE
        </div>

        <div className="flex gap-1 px-2 py-[6px] items-center justify-center bg-viper-darkGrayishBlue">
          <div className="text-white text-center font-work text-[11px] not-italic font-normal uppercase width-20">
            {bracketDetail?.tieBreakYtdRepScore}
          </div>
          <div className="text-viper-softGrayishBlue text-center font-work text-[11px] not-italic font-normal uppercase width-20">
            VS
          </div>
          <div className="text-white text-center font-work text-[11px] not-italic font-normal uppercase width-20">
            {bracketDetail?.opponentTieBreakYtdRepScore}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViperBracketInfo;
