import { ProfileCategory } from "../../interfaces/profile";

const data = {
  sales_performance: [
    {
      text: "BEST WEEK EFP",
      value: 38,
    },
    {
      text: "EFP RANK",
      value: 2,
    },
    {
      text: "WTD FP",
      value: 14,
    },
    {
      text: "TODAY FP",
      value: "0",
    },
    {
      text: "WTD TOTAL PRMR",
      value: "$1.78K",
    },
    {
      text: "AVG. TOTAL PRMR",
      value: "$131",
    },
  ],
};

export default function PersonalStats({ stats }: { stats: any }) {
  return stats ? (
    <div className="px-3 py-3">
      <div>
        <div className="font-bold mb-2">Sales Performance</div>
        {data.sales_performance.map((row) => (
          <div
            key={row.text}
            className="py-3 border-b border-[#0B0A0A] flex justify-between items-center"
          >
            <div className="text-neutral-200">{row.text}</div>
            <div className="font-semibold">{row.value}</div>
          </div>
        ))}
      </div>

      {Object.values(ProfileCategory)
        .reverse()
        .map((category) =>
          stats[category] ? (
            <div className="mt-8" key={category}>
              <div className="font-bold mb-4">
                {category}
                {Array.isArray(stats[category]) ? "s" : " Stats"}
              </div>
              {Array.isArray(stats[category]) ? (
                <table className="w-full">
                  <thead>
                    <tr className="border-b border-neutral-900">
                      <th className="text-neutral-100 text-sm font-normal text-left w-[40%] py-2 pl-2">
                        {category}
                      </th>
                      <th className="text-neutral-100 text-center text-sm font-normal">
                        Sales
                      </th>
                      <th className="text-neutral-100 text-right text-sm font-normal">
                        Installs
                      </th>
                      <th className="text-neutral-100 text-right text-sm font-normal pr-2">
                        Clean Sales
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {stats[category].map((row: any, index: number) => (
                      <tr key={index} className="border-b border-[#0B0A0A]">
                        <td className="text-neutral-100 text-left w-[40%] py-3 pl-2">
                          {row.name}
                        </td>
                        <td className="text-neutral-100 text-center">
                          {row.fields.sales.value}
                        </td>
                        <td className="text-neutral-100 text-center">
                          {row.fields.installs.value}
                        </td>
                        <td className="text-right font-semibold pr-2">
                          {row.fields.clean_sales.value}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <table className="w-full">
                  <thead>
                    <tr className="border-b border-neutral-900">
                      <th className="text-neutral-100 text-sm font-normal text-left w-[50%] py-2 pl-2">
                        Stat
                      </th>
                      <th className="text-neutral-100 text-center text-sm font-normal">
                        Rank
                      </th>
                      <th className="text-neutral-100 text-right text-sm font-normal pr-2">
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.values(stats[category].fields).map(
                      (row: any, index: number) => (
                        <tr key={index} className="border-b border-[#0B0A0A]">
                          <td className="text-neutral-100 text-left w-[50%] py-3 pl-2">
                            {row.label}
                          </td>
                          <td className="text-neutral-100 text-center">
                            {!!row.rank && (
                              <span className="px-1 text-xs rounded border border-[#655F5F]">
                                {row.rank}
                              </span>
                            )}
                          </td>
                          <td className="text-right font-semibold pr-2">
                            {row.value}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              )}
            </div>
          ) : (
            <></>
          )
        )}
    </div>
  ) : (
    <></>
  );
}
