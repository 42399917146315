import { useNavigate } from "react-router-dom";

import productionReportIcon from "../../assets/icons/ProductionReport.png";

import { CompetitionIndex, CompetitionLink } from "../../const/competition";
import { FavoriteType } from "../../const/enums/FavoriteType";
import { PageType } from "../../const/enums/PageType";

import useParsedFavorites from "../../hooks/useParsedFavorites";

interface FavoritesProps {
  closeMenu: () => void;
  onEditFavorites: () => void;
}

const Favorites = ({ closeMenu, onEditFavorites }: FavoritesProps) => {
  const navigate = useNavigate();

  const parsedFavorites = useParsedFavorites();

  const onFavoriteClick = (favorite: any) => {
    if (favorite.favoriteType === FavoriteType.Competition) {
      if (favorite.favoriteId === CompetitionIndex[PageType.CompetitionViper]) {
        navigate(`/competitions/${CompetitionLink[PageType.CompetitionViper]}`);
      } else if (
        favorite.favoriteId === CompetitionIndex[PageType.CompetitionTheCup]
      ) {
        navigate(
          `/competitions/${CompetitionLink[PageType.CompetitionTheCup]}`
        );
      }
    } else {
      navigate(`/report?alias=${favorite.alias}`);
    }
    closeMenu();
  };

  return (
    <div className="flex flex-col items-start px-[12px] gap-[12px]">
      <div className="flex justify-between items-center gap-[10px] self-stretch">
        <h2 className="text-opposite-highlight text-lg not-italic font-semibold leading-[120%]">
          Favorites
        </h2>
        <button className="px-sm py-s" onClick={onEditFavorites}>
          <span className="text-neutral-900 font-primary text-sm not-italic font-semibold leading-[120%]">
            Edit
          </span>
        </button>
      </div>

      <div className="flex items-center overflow-x-auto w-full">
        <div className="flex gap-sm items-center ">
          {parsedFavorites.map((favorite, index) => {
            return (
              <div
                className="flex flex-col items-center gap-[7px] rounded-[4px] bg-item-dark-gradient p-[8px] h-[123px] transition-all duration-300 brightness-100 hover:filter hover:brightness-125 cursor-pointer"
                key={index}
                onClick={() => onFavoriteClick(favorite)}
              >
                <img
                  src={favorite.imageUrl ?? productionReportIcon}
                  alt="Production Report"
                  srcSet=""
                  className="w-[64px] h-[64px] min-w-[64px] min-h-[64px] flex-shrink-0 rounded-[64px] shadow-pen-umbra-1 bg-gradient-1"
                />

                <p className="text-base-highlight text-center font-primary text-xs not-italic font-semibold leading-[120%] max-w-[64px] line-clamp-2 overflow-hidden overflow-ellipsis break-words select-none">
                  {favorite?.reportName || favorite?.name}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Favorites;
