import { ChevronUpDownIcon } from "@heroicons/react/16/solid";
import IconButton from "../../components/Common/IconButton";

interface Props {
  onToggleNav: () => void;
}

export default function ProfileBreadcrumb({ onToggleNav }: Props) {
  return (
    <div
      className="border-t border-[#0B0A0A] flex justify-between py-2 px-3 shadow shadow-zinc-900 cursor-pointer"
      onClick={onToggleNav}
    >
      <div className="text-sm">Management / ...</div>
      <IconButton onClick={onToggleNav}>
        <ChevronUpDownIcon className="size-4 text-primaryText" />
      </IconButton>
    </div>
  );
}
