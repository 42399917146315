const TheCupBackIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="20"
      viewBox="0 0 28 20"
      fill="none"
      className={className}
    >
      <path
        d="M20.2155 5.48761H4.06605L7.37016 2.09039C7.83528 1.61217 7.83528 0.836941 7.37016 0.358718C6.90491 -0.119641 6.15107 -0.119505 5.68582 0.358718L0.348837 5.84638C-0.116279 6.3246 -0.116279 7.09982 0.348837 7.57804L5.68582 13.0654C5.91844 13.3046 6.22318 13.4241 6.52792 13.4241C6.83266 13.4241 7.1374 13.3045 7.37003 13.0654C7.83514 12.5872 7.83514 11.812 7.37003 11.3338L4.06592 7.93654H20.2154C22.7935 7.93654 24.8909 10.0931 24.8909 12.7438C24.8909 15.3945 22.7935 17.5511 20.2154 17.5511H10.3915C9.73371 17.5511 9.20058 18.0992 9.20058 18.7755C9.20058 19.4518 9.73371 20 10.3915 20H20.2154C24.1068 20 27.2727 16.7448 27.2727 12.7438C27.2727 8.74279 24.1069 5.48761 20.2155 5.48761Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TheCupBackIcon;
