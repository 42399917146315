import SearchIcon from "../../../icons/SearchIcon";

const TheCupSearchBar = () => {
  return (
    <div className="flex items-center border py-3 px-[19px] gap-3 rounded-[6px] border-thecup-darkGrayishBlue bg-thecup-shadowedSlate">
      <SearchIcon className="text-white text-opacity-50" />

      <input
        type="text"
        placeholder="Search..."
        className="outline-none ml-2 w-full bg-transparent text-white text-opacity-50 font-plus text-sm not-italic font-semibold leading-[150%]"
      />
    </div>
  );
};
export default TheCupSearchBar;
